import React from 'react';

import {
  maxFileSizeMB,
  allowedFileTypes,
} from '../lib';

const FileDropzoneCloser = () => {

  return (
    <div>
      <p className='pb-2'>
        If there are any files that you would like to provide to us you can attach them here.
      </p>
      <p className='text-sm pb-2'>
        <span className='font-bold'>Maximum upload size:</span> {maxFileSizeMB}MB
      </p>
      <p className='text-sm pb-2'>
        <span className='font-bold'>Allowed file types:</span> {allowedFileTypes.join(', ')}
      </p>
    </div>
  );
};

export default FileDropzoneCloser;

