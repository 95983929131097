import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  CardCondensed,
  CardDate,
  CardSubheader,
  Image,
} from '../Elements';

import * as styles from '../EveryLayout/el.module.css';

const BlogPostAlt = ({ node }) => (
  <div className={`_center ${styles._center} h-full`}>
    <div className="group dark-bg">
      <div className="relative z-0 !aspect-w-4 !aspect-h-3">
        {node.data.main_image?.gatsbyImageData
          ? (
          <GatsbyImage
            image={node.data.main_image.gatsbyImageData}
            alt={node.data.main_image.alt || ''}
            className="object-cover"
          />
          ) : (
          <>
            {( node.data?.main_image?.url )
              && (
              <Image src={node.data.main_image.url} alt={node.data.main_image.alt} />
            )}
          </>
        )}
      </div>
      <div className="-mt-8 w-[90%] mx-auto relative z-10">
        <CardCondensed>
          <CardDate>{node.data.date}</CardDate>
          <CardSubheader>
            <Link to={node.url} className="no-underline group-[.dark-bg]:text-downy-400">{node.data.title?.text}</Link>
          </CardSubheader>
        </CardCondensed>
      </div>
    </div>
  </div>
);

BlogPostAlt.propTypes = {
  node: PropTypes.object.isRequired,
};

export default BlogPostAlt;