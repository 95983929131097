import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const defaultClasses = `inline-flex items-center justify-center border
  border-transparent text-center text-[.9rem] tracking-wide uppercase px-4 py-2
  rounded-xl  no-underline transition duration-hover`;

const Button = (props) => {
  const { children, className, colour, to } = props;

  const attrs = {
    target: props.target ? props.target : null,
    style: props.style ? props.style : null,
    rel: props.rel ? props.rel : null,
  };

  switch (colour) {
    case 'blue':
      return (
        <Link
          to={to}
          className={`${defaultClasses} bg-primary-500 hover:bg-primary-600 text-white hover:text-white shadow-sm ${className || ''}`}
          {...attrs}
        >
          {children}
        </Link>
      );
    case 'coral':
      return (
        <Link
          to={to}
          className={`${defaultClasses} bg-coral-a11y-500 hover:bg-coral-a11y-600 text-white hover:text-white shadow-sm ${className}`}
          {...attrs}
        >
          {children}
        </Link>
      );
    case 'pine green':
      return (
        <Link
          to={to}
          className={`${defaultClasses} bg-pine-green-500 hover:bg-pine-green-600 text-white hover:text-white shadow-sm ${className}`}
          {...attrs}
        >
          {children}
        </Link>
      );
    case 'downy':
      return (
        <Link
          to={to}
          className={`${defaultClasses} bg-downy-500 hover:bg-downy-600 text-color-base hover:text-color-base shadow-sm ${className}`}
          {...attrs}
        >
          {children}
        </Link>
      );
    default:
      return (
        <Link
          to={to}
          className={`${defaultClasses} bg-dark-gray-500 hover:bg-dark-gray-600 text-white  hover:text-white shadow-sm ${className}`}
          {...attrs}
        >
          {children}
        </Link>
      );
  }
};

Button.propTypes = {
  props: PropTypes.shape({
    to: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    colour: PropTypes.oneOf([ 'blue', 'coral', 'pine green', 'downy', 'dark gray', 'light gray' ]),
  }),
};

export default Button;
