import React from 'react';
import {
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import GenericIcon from './GenericIcon';

const Icon = ({ Icon, className }) => (
  <Icon className={className} aria-hidden="true" />
);

const ExternalLinkIcon = () => (
  <ArrowTopRightOnSquareIcon className="inline ml-1 -mt-[3px] h-4" aria-hidden="true" />
);

const ContactIcon = () => (
  <PhoneIcon className="h-full mx-auto" aria-hidden="true" />
);

const ContactBoxyIcon = () => (
  <div className="mx-auto rounded-xl w-16 h-16 p-4 bg-primary-500 text-white">
    <PhoneIcon className="h-full" aria-hidden="true" />
  </div>
);

const ContactRoundIcon = () => (
  <div className="mx-auto rounded-full w-16 h-16 p-4 text-white">
    <PhoneIcon className="h-full" aria-hidden="true" />
  </div>
);

const ContactNoBgIcon = () => (
  <div className="mx-auto rounded-full w-12 h-12">
    <PhoneIcon className="h-full" aria-hidden="true" />
  </div>
);

const QuoteIcon = () => (
  <PencilSquareIcon className="h-full mx-auto" aria-hidden="true" />
);

const QuoteBoxyIcon = () => (
  <div className="mx-auto rounded-xl w-16 h-16 p-4 bg-coral-a11y-500 text-white">
    <PencilSquareIcon className="h-full" aria-hidden="true" />
  </div>
);

const QuoteRoundIcon = () => (
  <div className="mx-auto rounded-full w-16 h-16 p-4 text-white">
    <PencilSquareIcon className="h-full" aria-hidden="true" />
  </div>
);

const QuoteNoBgIcon = () => (
  <div className="mx-auto rounded-full w-12 h-12">
    <PencilSquareIcon className="h-full" aria-hidden="true" />
  </div>
);

const ServiceIcon = (props) => {
  const ComponentType = props.icon || GenericIcon;
  return (
    <div className="rounded-xl w-16 h-16 p-4 bg-pine-green-500 text-white">
      <ComponentType className="h-full" aria-hidden="true" />
    </div>
  );
};

export {
  Icon,
  ContactIcon,
  ContactBoxyIcon,
  ContactRoundIcon,
  ContactNoBgIcon,
  ExternalLinkIcon,
  QuoteIcon,
  QuoteBoxyIcon,
  QuoteRoundIcon,
  QuoteNoBgIcon,
  ServiceIcon,
};