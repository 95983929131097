import React from 'react';
import PropTypes from 'prop-types';

import {
  Footer,
  Header,
} from './Layout';

import '../styles/base.css';
import '../styles/fluid.css';

const Layout = ({ pageContext, children, location }) => {

  // Some pages are self-contained. See onCreatePages() in gatsby-node.js.
  if (pageContext.layout === false) {
    return <>{children}</>;
  }

  return (
      <>
        <header id="header" className="relative z-50 max-w-8xl mx-auto">
          <Header />
        </header>
        <main id="main">
          {children}
        </main>
        <footer id="footer" className="mx-auto mt-20">
          <Footer />
        </footer>
      </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
  }).isRequired,
};

export default Layout;
