const enabled = process.env.GATSBY_FORMS_ENABLED === 'true';

const endpoints = {
  test: process.env.GATSBY_FORMS_ENDPOINT_TEST,
  contact: process.env.GATSBY_FORMS_ENDPOINT_CONTACT,
  'request-a-quote': process.env.GATSBY_FORMS_ENDPOINT_REQUEST_A_QUOTE,
};

const maxFileSize = 25 * 1024 * 1024; // 25MB

// for display purposes only, not used in FileDropzone
const maxFileSizeMB = maxFileSize / 1024 / 1024;

//pdf, eps, ai, and cdr.

const allowedFileTypes = [
  'pdf', 'eps', 'ai', 'cdr',
];

// to be used in FileDropzone for allowed file types
const allowedFileTypesString = '.' + allowedFileTypes.join(',.');


export {
  endpoints,
  maxFileSize,
  maxFileSizeMB,
  allowedFileTypes,
  allowedFileTypesString,
  enabled,
 };
