import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Image,
} from '../Elements';

const BlogPost = ({ node }) => (
  <div className="p-4 border"> {/* #component card ? */}
    <h3 className="text-xl mb-4">
      <Link to={node.url} className="font-serif no-underline">{node.data.title.text}</Link>
    </h3>
    {node.data.main_image?.gatsbyImageData
      ? (
        <div>
          <GatsbyImage
            image={node.data.main_image.gatsbyImageData}
            alt={node.data.main_image.alt || ''}
            className="object-cover"
          />
        </div>
      ) : (
        <>
          {( node.data?.main_image?.url )
            && (
            <div>
              <Image src={node.data.main_image.url} alt={node.data.main_image.alt} />
            </div>
          )}
        </>
      )
    }
    <div className="prose prose-custom bg-white px-2 py-4">
      <time className="mb-3 font-bold text-sm">{node.data.date}</time>
      <div>
        <span dangerouslySetInnerHTML={{ __html: node.data.teaser.html }} />
        &hellip; <Link to={node.url} aria-label={`Read more about ${node.data.title.text}`}>Read more</Link> <span aria-hidden="true">&rarr;</span>
      </div>
    </div>
  </div>
);

BlogPost.propTypes = {
  node: PropTypes.object.isRequired,
};

export default BlogPost;