import React from 'react';

import Image1 from '../../../static/images/Royal-Stamp-logo.svg';
import Image2 from '../../../static/images/Royal-Stamp-logo-icon.svg';
import Image3 from '../../../static/images/Royal-Stamp-logo-white.svg';
import Image4 from '../../../static/images/Royal-Stamp-logo-black.svg';

const Logo = (props) => {

  const attrs = {
    className: props.className ? props.className : 'max-w-[14rem] sm:max-w-xs',
  };

  return (
    <div {...attrs}>
      <img
        src={Image1}
        width="294"
        height="102"
        className="w-full"
        alt=""
      />
    </div>
  );
};

const LogoIcon = (props) => {

  const attrs = {
    className: props.className ? props.className : 'sm:max-w-xs',
  };

  return (
    <div {...attrs}>
      <img
        src={Image2}
        width="280"
        height="248"
        className="w-full"
        alt=""
      />
    </div>
  );
};

const LogoWhite = (props) => {

  const attrs = {
    className: props.className ? props.className : 'sm:max-w-xs',
  };

  return (
    <div {...attrs}>
      <img
        src={Image3}
        width="280"
        height="248"
        className="w-full"
        alt=""
      />
    </div>
  );
};

const LogoBlack = (props) => {

  const attrs = {
    className: props.className ? props.className : 'sm:max-w-xs',
  };

  return (
    <div {...attrs}>
      <img
        src={Image4}
        width="280"
        height="248"
        className="w-full"
        alt=""
      />
    </div>
  );
};

export {
  Logo,
  LogoIcon,
  LogoWhite,
  LogoBlack,
};