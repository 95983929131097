import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const MarqueeItem = ({ node }) => {
  return (
    <div
      className="block px-3 sm:px-4 lg:px-8 py-4"
    >
      <span className="text-downy-900 uppercase tracking-wide text-md lg:text-lg xl:text-md whitespace-nowrap">{node.label}</span>
    </div>
  );
};

const Marquee = () => {
  const { allMenuItem } = useStaticQuery(graphql`
    query {
      allMenuItem (
        filter: {
          uid: {
            regex: "/^complete-signage-solutions-services/"
          }
        }
        sort: {
          weight: ASC
        }
      ) {
        edges {
          node {
            id
            label
            parentUid
            path
            prismicPage {
              data {
                summary
              }
            }
            uid
          }
        }
      }
    }
  `);

  return (
    <div className="flex whitespace-no-wrap overflow-x-scroll motion-safe:overflow-x-hidden">
      <div className="relative group">
        <div className="flex motion-safe:animate-marquee group-hover:pause">
          {allMenuItem.edges.map(({ node }) => (
            <MarqueeItem key={`scroll-1-${node.id}`} node={node} />
          ))}
        </div>
        <div className="flex absolute top-0 motion-safe:animate-marquee2 group-hover:pause">
          {allMenuItem.edges.map(({ node }) => (
            <MarqueeItem key={`scroll-2-${node.id}`} node={node} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Marquee;