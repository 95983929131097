import React from 'react';
import PropTypes from 'prop-types';
import {
  InputText,
  InputEmail,
  InputTel,
  Radio,
} from '@othermachines/react-form-kit';

import { Fieldset } from './';

import { inputTheme, radioTheme } from '../lib';

const ContactInformation = ({
  register,
  formState,
}) => {

  return (
    <Fieldset legend="Contact Information">
      <InputText
        inputTextId='Full-name'
        inputTextName='Full-name'
        register={register}
        formState={formState}
        registration={{ required: 'Full name is required' }}
        requiredText='(required)'
        label='Full name'
        autoComplete='name'
        theme={inputTheme}
      />
      <InputText
        inputTextId='Organization'
        inputTextName='Organization'
        register={register}
        formState={formState}
        label='Organization'
        autoComplete='organization'
        theme={inputTheme}
      />
      <InputEmail
        inputEmailId='Email'
        inputEmailName='Email'
        register={register}
        formState={formState}
        registration={{ required: 'Email is required' }}
        requiredText='(required)'
        label='Email'
        autoComplete='email'
        theme={inputTheme}
      />
      <InputTel
        inputTelId='Phone'
        inputTelName='Phone'
        register={register}
        formState={formState}
        registration={{ required: 'Phone is required' }}
        requiredText='(required)'
        label='Phone'
        autoComplete='tel'
        theme={inputTheme}
      />

      <Radio
        radioId='Preferred-contact-method'
        radioName='Preferred-contact-method'
        legend='Preferred method of contact'
        options={[
          { value: 'email', label: 'Email' },
          { value: 'phone', label: 'Phone' },
        ]}
        register={register}
        formState={formState}
        columns={2}
        theme={radioTheme}
      />

    </Fieldset>
  );
};

ContactInformation.propTypes = {
  register: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
};


const defaultValues = {
  'Full-name': '',
  'Organization': '',
  'Email': '',
  'Phone': '',
  'Preferred-contact-method': '',
};

const fields = [
  'Full-name',
  'Organization',
  'Email',
  'Phone',
  'Preferred-contact-method',
];

export default ContactInformation;
export { defaultValues, fields };
