import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ThemeProvider,
  InputText,
  InputEmail,
  InputTel,
  Textarea,
} from '@othermachines/react-form-kit';

import {
  FormButton,
} from '../Elements';
import { Notification } from './Shared';

import {
  applyDefaultDisplay,
  applyDefaultLayout,
  applyGlobalDisplay,
  applyGlobalLayout,
  inputTheme,
  textareaTheme,
  FileTypeError,
  FileSizeError,
  sendForm
} from './lib';

const FormContact = () => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const defaultValues = {
    'Full-name': '',
    'Email': '',
    'Phone': '',
    'Message': '',
  };

  const fields = [
    'Full-name',
    'Email',
    'Phone',
    'Message',
  ];

  const {
    register,
    handleSubmit,
    formState,
    reset,
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  });


  const onSubmit = (data) => {
    if (data.flywheel) {
      return;
    }

    setDialogOpen(true);
    setStatus('Submitting...');
    sendForm({ data, fields, endpoint: 'contact' })
      .then((response) => {
        setStatus('Success!');
        setSubmissionComplete(true);
        reset(defaultValues);
      })
      .catch((error) => {
        setSubmissionComplete(true);

        if (error instanceof FileTypeError) {
          setStatus(error.message);
        }
        if (error instanceof FileSizeError) {
          setStatus(error.message);
        }
        if (error instanceof TypeError) {
          setStatus('There was a network error. Please try again shortly.');
        }
        if (error instanceof Error) {
          setStatus(`There was an error saving your submission: ${error.message}`);
        }
      });
  };

  return (
    <>
      <Notification
        title="Contact form submission"
        status={status}
        errorMessages={[]}
        submissionComplete={submissionComplete}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
      <ThemeProvider
        applyDefaultDisplay={applyDefaultDisplay}
        applyDefaultLayout={applyDefaultLayout}
        applyGlobalDisplay={applyGlobalDisplay}
        applyGlobalLayout={applyGlobalLayout}
      >

        <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 gap-y-6">
          <InputText
            inputTextId='Full-name'
            inputTextName='Full-name'
            register={register}
            formState={formState}
            registration={{ required: 'Full name is required' }}
            requiredText='(required)'
            label='Full name'
            autoComplete='name'
            theme={inputTheme}
          />
          <InputEmail
            inputEmailId='Email'
            inputEmailName='Email'
            register={register}
            formState={formState}
            registration={{ required: 'Email is required' }}
            requiredText='(required)'
            label='Email'
            autoComplete='email'
            theme={inputTheme}
          />
          <InputTel
            inputTelId='Phone'
            inputTelName='Phone'
            register={register}
            formState={formState}
            registration={{ required: 'Phone is required' }}
            requiredText='(required)'
            label='Phone'
            autoComplete='tel'
            theme={inputTheme}
          />
          <Textarea
            textareaId='Message'
            textareaName='Message'
            register={register}
            formState={formState}
            label='Message'
            theme={textareaTheme}
          />

          <input type="hidden" value="" name="flywheel" />
          <div className="max-w-[10rem]">
            <FormButton type="submit">
              Submit
            </FormButton>
          </div>
        </form>
      </ThemeProvider>
    </>
  );
};

export default FormContact;
