import React, { Fragment, forwardRef } from 'react'
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'

import { Spinner } from './';
import { FormButton } from '../../Elements';

/**
 *  This component shows messages about the form submission to the user.
 *
 * @param {string} title - The title of the notification.
 * @param {string} status - The status of the notification.
 * @param {string} errorMessage - The error message to display.
 * @param {boolean} submissionComplete - Whether the submission is complete.
 * @param {boolean} open - Whether the notification is open.
 * @param {function} setOpen - The function to set the notification open state.
 * @param {object} ref - The reference to the notification element.
 * @returns {ReactNode} - A React element that renders a notification.
 */
const Notification = forwardRef(({
  title,
  status,
  errorMessage,
  submissionComplete,
  open,
  setOpen,
}, ref) => {
  return (
    <>
      <div
        className="fixed z-50 pointer-events-none inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end" ref={ref}>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={open}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-white pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <div role="status">
                      <p className="mb-2 text-base font-bold text-gray-900">{title}</p>
                      <p className="mb-2 text-base text-gray-700">
                        {status}
                      </p>
                      {!!errorMessage && (
                        <>
                          <p>There was an error submitting your form.</p>
                          <p className="mb-2 text-base text-gray-700">
                            {errorMessage}
                          </p>

                          <p className="mb-2 text-base text-gray-700">
                            For assistance, please contact <a href="mailto:sales@royalstamp.ca">royalstamp.ca</a>.
                          </p>
                        </>
                      )}
                    </div>
                    <div className="mt-6 mb-4 flex space-x-7">
                      <FormButton
                        type="submit"
                        onClick={() => {
                          setOpen(false)
                        }}
                      >
                        Dismiss
                      </FormButton>
                    </div>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    {!submissionComplete ? (
                      <div className="flex-shrink-0">
                        <Spinner />
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex rounded-md text-gray-600 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        onClick={() => {
                          setOpen(false)
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
});

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  submissionComplete: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
Notification.defaultProps = {
  errorMessage: '',
};

export default Notification;
