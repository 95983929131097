import React from 'react';
import { motion, useReducedMotion } from 'framer-motion';

import Photo65Years from "../../../static/images/Royal-Stamp-65-years.svg";

const Animated65 = () => {
  let shouldReduceMotion = useReducedMotion()

  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, perspective: '400px', rotateY: shouldReduceMotion ? '0' : '80deg' },
        visible: { opacity: 1, rotateY: '0' },
      }}
      transition={{ duration: 0.5 }}
      initial='hidden'
      whileInView='visible'
      viewport
      className=""
    >
      <img src={Photo65Years} alt="Royal Stamp 65 years" className="max-w-xs mx-auto object-cover text-white" />
    </motion.div>
  );
};

export default Animated65;