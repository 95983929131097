import React from 'react';
import PropTypes from 'prop-types';

const defaultClasses = `h-4 w-4`;

const RadioIcon = ({ className, checked, titleId }) => {
  const opacity = checked ? 1 : 0;
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      className={`${defaultClasses} ${className}`}
      aria-labelledby={titleId}
      fillRule="evenodd"
      clipRule="evenodd"
    >

      <path
        stroke="#666"
        strokeWidth={1}
        d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#080341"
      />
      <circle
        cx="12"
        cy="12"
        r={5.5}
        fillOpacity={opacity}
        className='fill-primary-500'
      />
    </svg>
  );
};

RadioIcon.propTypes = {
  state: PropTypes.string,
};

export default RadioIcon;
