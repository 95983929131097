import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { MegaMenu, MegaMenuColumn } from './MegaMenu';

const MenuMore = () => {
  const { mainMenuItems, secondaryMenuItems } = useStaticQuery(graphql`
    query {
      mainMenuItems: allMenuItem (
        filter: {
          uid: {
            regex: "/^(signs-graphics|name-tags-name-plates|stamps-seals|industrial-marking)/"
          }
        }
        sort: {
          weight: ASC
        }
      ) {
        edges {
          node {
            id
            label
            parentUid
            path
            prismicPage {
              data {
                summary
              }
            }
            uid
          }
        }
      }
      secondaryMenuItems: allMenuItem (
        filter: {
          uid: {
            regex: "/^(about|blog|contact)/"
          }
          parentUid: {
            eq: ""
          }
        }
        sort: {
          weight: ASC
        }
      ) {
        edges {
          node {
            id
            label
            parentUid
            path
            prismicPage {
              data {
                summary
              }
            }
            uid
          }
        }
      }
    }
  `);

  return (
    <MegaMenu
      title="More ..."
      footer={false}
    >
      <MegaMenuColumn
        className="basis-[20rem] grow-[999]"
      >
        <div className="columns-3">
          {mainMenuItems.edges.map(({ node }) => {
            const firstChildren = mainMenuItems.edges.filter(({ node: child }) => child.parentUid === node.uid);
            if (!node.parentUid) {
              return (
                <div key={node.id} className="">
                  <p>
                    <Link to={node.path} className="no-underline hover:underline block text-sm uppercase font-bold mb-2">{node.label}</Link>
                  </p>
                  {firstChildren.length > 0
                    && (
                    <ul className="mb-4 bg-gray-100 p-3 pb-4 break-inside-avoid break-before-avoid">
                      {firstChildren.map(({ node: firstChild }) => {
                        return (
                          <li key={firstChild.id}>
                            <Link to={firstChild.path} className="no-underline hover:underline block text-sm mb-2">{firstChild.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              )
            }
            return null;
          })}
        </div>
        <div className="flex gap-6 mt-4">
          {secondaryMenuItems.edges.map(({ node }) => {
            if (!node.parentUid) {
              return (
                <div key={node.id}>
                  <p>
                    <Link to={node.path} className="no-underline hover:underline block text-sm uppercase font-bold pb-2 mb-2">{node.label}</Link>
                  </p>
                </div>
              )
            }
            return null;
          })}
        </div>
      </MegaMenuColumn>
    </MegaMenu>
  );
};

export default MenuMore;