import React from 'react';
import PropTypes from 'prop-types';

import {
  FadeInOpacity,
  FadeInStagger,
} from '../Elements';

const JewelGroupWrapper = ({ children }) => (
  <div className="relative !aspect-w-4 !aspect-h-3">
    <div>
      {children}
    </div>
  </div>
);

const JewelGroup = ({ theme }) => {

  switch (theme) {
    case 'groupA':
      return (
        <JewelGroupWrapper>
          <FadeInStagger>
            <FadeInOpacity className="z-0 flex w-[70%] h-[70%] absolute top-0 left-0">
              <Jewel colour="bg-downy-500" nowrap={true} />
            </FadeInOpacity>
            <FadeInOpacity className="z-10 flex w-[52%] h-[52%] absolute bottom-0 right-0">
              <Jewel colour="bg-coral-500" nowrap={true} />
            </FadeInOpacity>
          </FadeInStagger>
        </JewelGroupWrapper>
      );

    case 'groupB':
      return (
        <JewelGroupWrapper>
          <FadeInStagger>
            <FadeInOpacity className="z-0 flex w-[70%] h-[70%] absolute bottom-0 right-0">
              <Jewel colour="bg-downy-500" nowrap={true} />
            </FadeInOpacity>
            <FadeInOpacity className="z-10 flex w-[52%] h-[52%] absolute top-0 left-0">
              <Jewel colour="bg-pine-green-500" nowrap={true} />
            </FadeInOpacity>
          </FadeInStagger>
        </JewelGroupWrapper>
      );

    case 'groupC':
      return (
        <JewelGroupWrapper>
          <FadeInStagger>
            <FadeInOpacity className="z-0 flex w-[70%] h-[70%] absolute bottom-0 left-0">
              <Jewel colour="bg-primary-500" nowrap={true} />
            </FadeInOpacity>
            <FadeInOpacity className="z-10 flex w-[52%] h-[52%] absolute top-0 right-0">
              <Jewel colour="bg-pine-green-500" nowrap={true} />
            </FadeInOpacity>
          </FadeInStagger>
        </JewelGroupWrapper>
        );

    case 'groupD':
      return (
        <JewelGroupWrapper>
          <FadeInStagger>
            <FadeInOpacity className="z-0 flex w-[70%] h-[70%] absolute bottom-0 left-0">
              <Jewel colour="bg-coral-500" nowrap={true} />
            </FadeInOpacity>
            <FadeInOpacity className="z-10 flex w-[52%] h-[52%] absolute top-0 right-0">
              <Jewel colour="bg-primary-500" nowrap={true} />
            </FadeInOpacity>
          </FadeInStagger>
        </JewelGroupWrapper>
        );

    case 'groupE':
    default:
      return (
        <JewelGroupWrapper>
          <FadeInStagger>
            <FadeInOpacity className="z-0 flex w-[70%] h-[70%] absolute bottom-0 left-0">
              <Jewel colour="bg-downy-500" nowrap={true} />
            </FadeInOpacity>
            <FadeInOpacity className="z-10 flex w-[52%] h-[52%] absolute top-0 right-0">
              <Jewel colour="bg-coral-500" nowrap={true} />
            </FadeInOpacity>
          </FadeInStagger>
        </JewelGroupWrapper>
        );
  }
};

const Jewel = ({ colour, nowrap = false }) => {
  const Em = () => <div className={`w-full h-full rounded-lg md:rounded-xl 2xl:rounded-2xl ${colour} shadow-md`} />;
  if (nowrap) {
    return <Em />;
  }
  return (
    <JewelGroupWrapper>
      <Em />
    </JewelGroupWrapper>
  );
};

JewelGroupWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

JewelGroup.propTypes = {
  theme: PropTypes.oneOf(['default','groupA','groupB','groupC']),
};

Jewel.propTypes = {
  colour: PropTypes.oneOf(['bg-coral-500','bg-downy-500','bg-pine-green-500','bg-primary-500']).isRequired,
  nowrap: PropTypes.bool,
};

export {
  Jewel,
  JewelGroup,
  JewelGroupWrapper,
};
