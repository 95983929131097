import {
  FileTypeError,
  FileSizeError,
} from './Errors';

import {
  endpoints,
  maxFileSize,
  allowedFileTypes,
  enabled,
} from './FormConfig';

require('isomorphic-fetch');

/**
 * Sends a form to getform.io
 * @param {Object} options
 * @param {Object} options.data
 * @param {Object} options.fields array of field names to submit
 * @param {string} options.method
 * @returns {Promise}
 * @throws {TypeError} fetch failed
 * @throws {Error} fetch succeeded but response was not ok
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 * @usage
 * try {
 *  const response = await sendForm({ data, method });
 *  console.log(response);
 * } catch(e) {
 *  if (e instanceof FileTypeError) {
 *  console.error(e.message);
 * }
 * if (e instanceof FileSizeError) {
 *   console.error(e.message);
 * }
 *  if (e instanceof TypeError) {
 *   console.error('fetch failed');
 * }
 * if (e instanceof Error) {
 *  console.error('fetch succeeded but response was not ok');
 */

async function sendForm({
  data,
  fields,
  endpoint,
  method = 'POST'
}) {
  if (!endpoint) {
    throw new Error('Endpoint is required');
  }

  if (!endpoints[endpoint]) {
    throw new Error(`Endpoint ${endpoint} not found`);
  }

  // fields is required
  if (!fields) {
    throw new Error('Fields is required');
  }

  // fields must be an array
  if (fields !== undefined && !Array.isArray(fields)) {
    throw new Error('Fields must be an array');
  }

  // delete any fields that are not in the fields array
  Object.keys(data).forEach((key) => {
    if (!fields.includes(key)) {
      delete data[key];
    }
  });

  // get all files from data object
  const files = Object.entries(data).flatMap(([ key, val ]) => {
    if (Array.isArray(val) && val.length) {
      return val.filter((el) => el instanceof File);
    }
    return [];
  });

  const totalFileSize = files && files.reduce((total, file) => total + file.size, 0);

  if (totalFileSize > maxFileSize) {
    throw new FileSizeError(`File must be less than ${maxFileSize / 1024 / 1024}MB`);
  }

  // make sure all files are of the allowed type
  const fileTypes = files && files.map((file) => file.name.split('.').pop().toLowerCase());
  const fileTypesAllowed = files && fileTypes.every((fileType) => allowedFileTypes.includes(fileType));
  if (!fileTypesAllowed) {
    throw new FileTypeError(`File must be one of the following types: ${allowedFileTypes.join(', ')}`);
  }

  // construct FormData object from json data
  const formData = new FormData();

  // iterate over fields to append to formData
  fields.forEach((field) => {
    // Files come in as an array of File objects. We need to append each one
    // with their own key. We'll do that below.
    // Here we need to handle other array types, like checkboxes.
    if (Array.isArray(data[field]) && data[field].length && !(data[field][0] instanceof File)) {
      formData.append(field, data[field].join(', '));
    } else {
      if (data[field]) {
        formData.append(field, data[field]);
      }
    }
  });

  // append files to formData
  files.forEach((el, i) => {
    formData.append(`file_${i}`, el, el.name);
  });

  if (!enabled) {
    console.log('******* FORM DATA *******');
    // iterate over formData.entries() to see what's in it
    for (let pair of formData.entries()) {
      console.log(pair[0]+ ', ' + pair[1]);
    }
    console.log('******* FORM DATA *******');

    return { ok: true };
  }

  // throws a TypeError: Failed to fetch
  // e.name = 'TypeError'
  // e.message = 'Failed to fetch'
  const response = await fetch(endpoints[endpoint], {
    method,
    body: formData,
    headers: {
      'Accept': 'application/json',
    },
  });

  // throw an error if we received a non-200 response
  if (!response.ok) {
    const result = await response.json();
    throw new Error(result.message);
  }

  return response.json();

};

export default sendForm;
