import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Image } from '../Elements';
import { MegaMenu, MegaMenuColumn } from './MegaMenu';

const MenuIndustrial = () => {
  const { prismicPage, allPrismicPage } = useStaticQuery(graphql`
    query {
      prismicPage(
        uid: {
          eq: "industrial-marking"
        }
      ) {
        ...PrismicPageFragment
      }
      allPrismicPage(
        filter: {
          data: {
            parent: {
              uid: {
                eq: "industrial-marking"
              }
            }
          }
        }
        sort: {
          order: ASC
        }
      ) {
        edges {
          node {
            id
            data {
              image {
                thumbnails {
                  StandardLandscape {
                    gatsbyImageData(width: 400)
                  }
                }
              }
              title {
                text
              }
            }
            pagePath
          }
        }
      }
    }
  `);

  return (
    <MegaMenu
      title={prismicPage.data.title.text}
      summary={prismicPage.data?.summary || ''}
      path={prismicPage.pagePath}
      footer={false}
    >
      <MegaMenuColumn
        className="basis-0 grow-[999]"
        pattern={true}
      >
        <div className="grid grid-cols-4 gap-2">
          {allPrismicPage.edges.map(({ node }, i) => {
            return (
              <div key={node.id}>
                {node.data?.image?.thumbnails?.StandardLandscape?.gatsbyImageData
                  ? (
                  <Link to={node.pagePath} className="block">
                    <GatsbyImage
                      image={node.data.image.thumbnails.StandardLandscape.gatsbyImageData}
                      alt={node.data.image.thumbnails.StandardLandscape.alt || ''}
                      imgClassName="object-cover"
                    />
                  </Link>
                ) : (
                  <Image src="/images/placeholder-4-3.png" alt="" />
                )}
                <div>
                  <Link to={node.pagePath} className={`inline-block text-sm no-underline font-bold mt-2`}>{node.data.title.text}</Link>
                </div>
              </div>
            );
          })}
        </div>
      </MegaMenuColumn>
    </MegaMenu>
  );
};

export default MenuIndustrial;