import React from 'react';

const StampIcon = (props) => (
  <svg viewBox="0 0 64 64" {...props}>
    <g>
      <rect fill="none" />
      <path fill="currentColor" d="M17.406 38.553l29.188 0c0.671,0 1.22,0.652 1.22,1.44l0 10.102c0,0.788 -0.553,1.44 -1.22,1.44l-29.188 0c-0.667,0 -1.22,-0.648 -1.22,-1.44l0 -10.102c0,-0.792 0.549,-1.44 1.22,-1.44zm18.628 -26.087c2.778,-0.04 5.031,2.415 5.031,5.392 0,1.719 -0.648,3.764 -0.941,5.467 -2.293,1.767 -3.786,4.65 -3.786,7.907 0,1.981 0.554,3.823 1.502,5.36l-0.09 0.525 -11.5 0 -0.15 -0.875c0.822,-1.464 1.295,-3.178 1.295,-5.01 0,-3.108 -1.36,-5.875 -3.476,-7.657 -0.306,-1.779 -0.984,-3.92 -0.984,-5.717 0,-2.977 2.253,-5.392 5.032,-5.392l0.038 0 0 0c5.75,0 2.188,0 7.938,0l0 0.001 0.091 -0.001z" />
    </g>
  </svg>
);

export default StampIcon;
