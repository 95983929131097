import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Cluster,
  Switcher
} from '../EveryLayout';
import { MoreLink } from '../Misc';

import { MegaMenu, MegaMenuColumn } from './MegaMenu';

const MenuSolutions = () => {
  const { menuItem, allMenuItem, allPrismicProject } = useStaticQuery(graphql`
    query {
      menuItem (
        uid: {
          eq: "complete-signage-solutions"
        }
      ) {
        id
        path
        prismicPage {
          data {
            summary
          }
        }
        label
      }
      allMenuItem (
        filter: {
          parentUid: {
            eq: "complete-signage-solutions"
          }
          uid: {
            ne: "complete-signage-solutions-projects"
          }
        }
        sort: {
          weight: ASC
        }
      ) {
        edges {
          node {
            id
            path
            prismicPage {
              data {
                summary
              }
            }
            label
          }
        }
      }
      allPrismicProject (
        limit: 2
        sort: [
          {
            data: {
              sticky: DESC
            }
          },
          {
            data: {
              title: {
                text: ASC
              }
            }
          }
        ]
      ) {
        edges {
          node {
            id
            uid
            data {
              images {
                image {
                  alt
                  gatsbyImageData(
                    width: 600
                  )
                  thumbnails {
                    Landscape {
                      alt
                      gatsbyImageData(
                        width: 900
                      )
                    }
                  }
                }
              }
              sticky
              title {
                text
              }
            }
          }
        }
      }
    }
  `);

  return (
    <MegaMenu
      title={menuItem.label}
      summary={menuItem.prismicPage[0]?.data?.summary || ''}
      path={menuItem.path}
      footer={true}
    >
      <MegaMenuColumn
        className="basis-[20rem] grow-[999]"
      >
        <Switcher>
          <div className="grid gap-2 md:grid-cols-2">
            {allMenuItem.edges.map(({ node }) => {
              const pageData = node.prismicPage[0] || null;
              return (
                <Link
                  key={node.id}
                  to={node.path}
                  className="no-underline px-2 py-2 flex items-start rounded-sm hover:bg-light-gray-200 transition ease-in-out duration-150"
                >
                  <div className="">
                    <p className="text-sm font-bold">{node.label}</p>
                    {( pageData.data?.summary )
                      && (
                      <p className="mt-1 text-sm font-light text-color-base">{pageData.data.summary}</p>
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        </Switcher>
      </MegaMenuColumn>
      <MegaMenuColumn
        className="basis-[30rem] grow"
        pattern={true}
      >
        <div className="max-w-xl">
          <h3 className="text-sm font-bold tracking-wide uppercase">Our Projects</h3>
          <ul className="grid grid-cols-2 gap-2 mt-4">
            {allPrismicProject.edges.map(({ node }) => (
              <li key={`menu-project-${node.id}`} className="max-w-xs">
                <Link
                  to={`/complete-signage-solutions/projects/${node.uid}/`}
                  className="block mb-4 no-underline"
                >
                  <Cluster>
                    {node.data.images.length > 0
                      && node.data.images[0].image?.thumbnails?.Landscape?.gatsbyImageData
                      && (
                      <div className="shrink">
                        <GatsbyImage
                          image={node.data.images[0].image.thumbnails.Landscape.gatsbyImageData}
                          alt={node.data.images[0].alt || node.data.images[0].image.thumbnails.Landscape.alt || ''}
                        />
                      </div>
                    )}
                    <div className="flex-1">
                      <h4 className="text-sm font-bold">{node.data.title?.text}</h4>
                    </div>
                  </Cluster>
                </Link>
              </li>
            ))}
          </ul>
          <div className="text-sm font-bold">
            <MoreLink to={`/complete-signage-solutions/projects/`}>More Projects</MoreLink>
          </div>
        </div>
      </MegaMenuColumn>
    </MegaMenu>
  );
};

export default MenuSolutions;