import React from 'react';

import { Button } from './';
import { MoreLinkAlt } from '../Misc';

const Card = ({ children, className = '' }) => {
  return (
    <div className={`pl-6 pr-8 pt-8 pb-10 rounded-xl lg:rounded-2xl bg-primary-500 shadow-md lg:shadow-lg ${className}`}>
      <div className="prose prose-custom">
        {children}
      </div>
    </div>
  );
};

const CardCondensed = ({ children, className = '' }) => {
  return (
    <div className={`group condensed px-5 pt-4 pb-8 rounded-lg lg:rounded-xl bg-primary-500 shadow-md lg:shadow-lg ${className}`}>
      <div className="prose prose-custom">
        {children}
      </div>
    </div>
  );
};

const CardHero = ({ children }) => {
  return (
    <div className="group hero pt-8 pb-10 px-6 md:pt-4 md:pb-6 2xl:pt-8 2xl:pb-10 rounded-xl lg:rounded-none lg:rounded-tr-3xl bg-primary-500 shadow-md lg:shadow-lg">
      <div className="prose prose-custom">
        {children}
      </div>
    </div>
  );
};

const CardHeader = ({ children }) => (
  <h2 className="text-sm font-normal uppercase tracking-wider text-downy-400">
    {children}
  </h2>
);

const CardSubheader = ({ children }) => (
  <h3 className="headline text-xl md:text-2xl text-primary-100 mt-0 sm:group-[.condensed]:text-lg md:group-[.condensed]:text-xl group-[.hero]:mb-2">
    {children}
  </h3>
);

const CardHeroSubheader = ({ children }) => (
  <h3 className="text-2xl font-serif md:text-3xl text-downy-400 mt-0 mb-2">
    {children}
  </h3>
);

const CardDate = ({ children }) => (
  <time className="text-sm sm:text-xs uppercase tracking-wider text-primary-100">{children}</time>
);

const CardBody = ({ children }) => (
  <div className="text-primary-100 group-[.hero]:mb-2">
    {children}
  </div>
);

const CardButton = ({ children, to }) => (
  <Button colour="pine green" to={to || '#'} className="mt-4">
    {children}
  </Button>
);

const CardMoreLink = ({ children, to }) => (
  <MoreLinkAlt to={to || '#'}>
    {children}
  </MoreLinkAlt>
);

export {
  Card,
  CardBody,
  CardButton,
  CardCondensed,
  CardDate,
  CardHeader,
  CardHero,
  CardHeroSubheader,
  CardMoreLink,
  CardSubheader,
};
