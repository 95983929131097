import React from 'react';
import {
  EnvelopeIcon,
  MapPinIcon,
} from '@heroicons/react/24/solid';

import { LogoWhite } from '../Brand';
import {
  BeaverIcon,
  ContactIcon,
  ExternalLinkIcon,
  StampIcon,
} from '../Misc';
import { SocialPages } from '../Navigation';
import {
  Cluster,
  Switcher,
} from '../EveryLayout';

const Footer = () => {
  return (
    <div className="relative group bg-dark bg-primary-600" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="relative z-10">
        <div className="max-w-[90rem] mx-auto py-16 px-8">
          <LogoWhite role="img" className="max-w-[16rem]" />
          <Switcher className="gap-8 justify-between mt-16">
            <div>
              <div className="prose prose-custom group-[.bg-dark]:text-white">
                <h3 className="uppercase font-normal text-lg leading-tight mb-2 text-downy-400">Downtown Edmonton</h3>
                <div>
                  <p>Head Office / Production Headquarters
                    <br />
                    10633 - 109 Street
                    <br />
                    Edmonton, Alberta T5H 3B5
                  </p>
                  <p>
                    <MapPinIcon className="inline h-4 w-4" aria-hidden="true" /> <a href="https://www.google.com/maps?ll=53.550695,-113.508164&z=14&t=m&hl=en&gl=US&mapclient=embed&q=10633+109+St+NW+Edmonton,+AB+T5H+3B5" rel="nofollow noreferrer" target="_blank">View Map<span className="whitespace-nowrap"><ExternalLinkIcon /></span></a>
                  </p>
                  <p>
                    Mon - Fri 8:00 am - 4:30 pm
                  </p>
                  <dl>
                    <div>
                      <dt className="sr-only">Phone number</dt>
                      <dd className="flex items-center">
                        <span className="h-4">
                          <ContactIcon />
                        </span>
                        <span className="ml-2">
                          (780) 426-4676
                        </span>
                      </dd>
                    </div>
                    <div>
                      <dt className="sr-only">Toll-free phone number</dt>
                      <dd className="flex items-center">
                        <span className="font-bold">
                          Toll Free:
                        </span>
                        <span className="ml-1">1-800-272-8859</span>
                      </dd>
                    </div>
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd className="flex items-center">
                        <EnvelopeIcon className="flex-shrink-0 h-4 w-4" aria-hidden="true" />
                        <span className="ml-2"><a href="mailto:sales@royalstamp.ca">sales@royalstamp.ca</a></span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div>
              <div className="prose prose-custom text-white">
                <h3 className="uppercase font-normal text-lg leading-tight mb-2 text-downy-400">Edmonton South Side</h3>
                  <div>
                    <p>
                      9853 - 63 Avenue
                      <br />
                      Edmonton, Alberta T6E 0G7
                    </p>
                    <p>
                      <MapPinIcon className="inline h-4 w-4" aria-hidden="true" /> <a href="https://www.google.com/maps?ll=53.499455,-113.485177&z=14&t=m&hl=en&gl=US&mapclient=embed&q=9853+63+Ave+NW+Edmonton,+AB+T6E+0G7" rel="nofollow noreferrer" target="_blank">View Map<span className="whitespace-nowrap"><ExternalLinkIcon /></span></a>
                    </p>
                    <p>
                      Mon - Fri 8:00 am – 4:30 pm
                      <br />
                      Closed during lunch 12:30 – 1:15
                    </p>
                    <dl>
                      <div>
                        <dt className="sr-only">Phone number</dt>
                        <dd className="flex items-center">
                          <span className="h-4">
                            <ContactIcon />
                          </span>
                          <span className="ml-2">
                            (780) 437-5533
                          </span>
                        </dd>
                      </div>
                      <div>
                        <dt className="sr-only">Email</dt>
                        <dd className="flex items-center">
                          <EnvelopeIcon className="flex-shrink-0 h-4 w-4" aria-hidden="true" />
                          <span className="ml-2"><a href="mailto:sales.south@royalstamp.ca">sales.south@royalstamp.ca</a></span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
            </div>
            <div className="lg:flex">
              <div />
              <div className="flex flex-wrap gap-8">

                <div>
                  <Cluster className="!gap-1">
                    <div className="w-16">
                      <BeaverIcon className="mr-3 -ml-1 h-14 w-14 group-[.bg-dark]:text-primary-100" aria-hidden="true" />
                    </div>
                    <div className="flex-1">
                      <a href="https://www.permitstamps.ca/" rel="noopener noreferrer" target="_blank" className="block max-w-[16rem] no-underline">
                        Buy APEGA Permit to Practice Stamps <span className="whitespace-nowrap">Online<ExternalLinkIcon /></span>
                      </a>
                    </div>
                  </Cluster>
                  <Cluster className="mt-4 !gap-1">
                    <div className="w-16">
                      <StampIcon className="mr-3 -ml-1 h-14 w-14 group-[.bg-dark]:text-primary-100" aria-hidden="true" />
                    </div>
                    <div className="flex-1">
                      <a href="https://rrs.utypia.com/UI/en-US" rel="noopener noreferrer" target="_blank" className="block max-w-[16rem] no-underline">
                        Buy Stamps & Corporate Seals <span className="whitespace-nowrap">Online<ExternalLinkIcon /></span>
                      </a>
                    </div>
                  </Cluster>
                </div>

                <SocialPages />
              </div>
            </div>
          </Switcher>
        </div>

        <div className="bg-primary-700">
          <div className="flex items-center justify-between max-w-[90rem] mx-auto py-16 px-8">
            <div className="prose prose-custom group-[.bg-dark]:text-white ">
              <p>
                &copy; {new Date().getFullYear()} Royal Rubber Stamp & Sign Co. Ltd. All rights reserved.
              </p>
            </div>
            {/*<DarkModeToggle />*/}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;
