import React from 'react';
import PropTypes from 'prop-types';

const Fieldset = ({ legend, children }) => {

  return (
    <fieldset className="grid grid-cols-1 gap-y-4 mt-8">
      <legend className="text-lg font-bold mb-4 border-b w-full">{legend}</legend>
      {children}
    </fieldset>
  );
}

Fieldset.proptTypes = {
  legend: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Fieldset;
