import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';

import {
  Button,
} from '../Elements';
import {
  Center,
  Cluster,
  Stack,
} from '../EveryLayout';
import {
  MenuButtonMobile,
  MenuCloseButton,
  MenuLinkButtonMobile,
  MenuLinkAltButtonMobile,
  MenuExtLinkButtonMobile,
} from '../Navigation';

import { useRequestAQuoteLink } from '../../hooks';

const MobileMenuNavigation = () => {
  const { mainMenuItems, secondaryMenuItems } = useStaticQuery(graphql`
    query {
      mainMenuItems: allMenuItem (
        filter: {
          uid: {
            regex: "/^(complete-signage-solutions|signs-graphics|name-tags-name-plates|stamps-seals|industrial-marking)/"
          }
          parentUid: {
            eq: ""
          }
        }
        sort: {
          weight: ASC
        }
      ) {
        edges {
          node {
            id
            label
            parentUid
            path
            prismicPage {
              data {
                summary
              }
            }
            uid
          }
        }
      }
      secondaryMenuItems: allMenuItem (
        filter: {
          uid: {
            regex: "/^(about|blog|contact)/"
          }
          parentUid: {
            eq: ""
          }
        }
        sort: {
          weight: ASC
        }
      ) {
        edges {
          node {
            id
            label
            parentUid
            path
            prismicPage {
              data {
                summary
              }
            }
            uid
          }
        }
      }
    }
  `);

  const transitionChildProps = {
    'as': 'li',
    'enter': 'transition ease-linear',
    'enterFrom': 'opacity-100',
    'enterTo': 'opacity-100',
    'leave': 'transition ease-linear duration-300',
    'leaveFrom': 'opacity-100',
    'leaveTo': 'opacity-100',
  };

  const requestAQuoteLink = useRequestAQuoteLink();

  return (
    <Transition
      as={Fragment}
      enter="transition ease duration-500 transform"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease duration-300 transform"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Popover.Panel
        focus
        className="bg-primary-500 fixed w-auto top-0 h-screen sm:w-[388px] inset-x-0 sm:right-0 sm:left-auto z-20 lg:hidden"
      >
        <div className="border-box h-full px-4 pt-4 pb-16">
          <div className="h-full">
            <div className="flex items-center justify-end">
              <div>
                <MenuCloseButton />
              </div>
            </div>
            <Stack className="h-full justify-end">
              <div>
                <nav className="mt-6">
                  <Stack as="ul">
                    <Transition.Child
                      {...{...transitionChildProps, enter: transitionChildProps.enter + ' delay-[200ms]'}}
                    >
                      <MenuLinkButtonMobile to="/">
                        Home
                      </MenuLinkButtonMobile>
                    </Transition.Child>
                    {mainMenuItems.edges.map(({ node }) => (
                      <Transition.Child
                        key={node.id}
                        {...{...transitionChildProps, enter: transitionChildProps.enter + ' delay-[800ms]'}}
                      >
                        <MenuLinkButtonMobile to={node.path}>
                          {node.label}
                        </MenuLinkButtonMobile>
                      </Transition.Child>
                    ))}
                  </Stack>
                </nav>
                <nav className="mt-6">
                  <Cluster as="ul" className="">
                    {secondaryMenuItems.edges.map(({ node }) => (
                      <li key={node.id} className="pr-2 py-2 text-center">
                        <MenuLinkAltButtonMobile to={node.path}>
                          {node.label}
                        </MenuLinkAltButtonMobile>
                      </li>
                    ))}
                  </Cluster>
                </nav>
              </div>
              <Stack className="mt-auto">
                <Center className="">
                  <Stack as="ul" className="mx-auto">
                    <li className="w-auto">
                      <MenuButtonMobile to={requestAQuoteLink}>
                        Request a Quote
                      </MenuButtonMobile>
                    </li>
                    <li className="">
                      <Button to="https://rrs.utypia.com/UI/en-US" colour="downy" rel="noopener noreferrer" target="_blank" className="w-full">
                        Online Store
                      </Button>
                    </li>
                  </Stack>
                </Center>
                <Center className="mt-6">
                  <Cluster className="mx-auto" style={{ '--switcher-basis': 'calc(( 5rem - 100%) * 999)' }}>
                    <MenuExtLinkButtonMobile to="http://www.flickr.com/photos/97766255@N05/">
                      Flickr
                    </MenuExtLinkButtonMobile>
                    <MenuExtLinkButtonMobile to="https://twitter.com/RoyalEdmonton">
                      Twitter
                    </MenuExtLinkButtonMobile>
                    <MenuExtLinkButtonMobile to="https://www.instagram.com/royaledmonton/">
                      Instagram
                    </MenuExtLinkButtonMobile>
                    <MenuExtLinkButtonMobile to="https://www.youtube.com/royaledmonton">
                      YouTube
                    </MenuExtLinkButtonMobile>
                  </Cluster>
                </Center>
              </Stack>
            </Stack>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  )
};

export default MobileMenuNavigation;
