import React from 'react';

const Label = ({ children }) => {

  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm bg-gray-100 mr-1 mb-2">
      {children}
    </span>
  );
};

export default Label;