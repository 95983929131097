import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, className = '', alt = ''}) => (
  <img src={src} alt={alt} className={`object-cover ${className}`} />
);

Image.propTypes = {
  src: PropTypes.node.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default Image;
