import React from 'react';
import {
  useStaticQuery,
  graphql
} from 'gatsby';

/**
 * This hook returns menu items in services and product categories. Top-level
 * items are excluded.
 *
 * @returns An object containing a property for each category.
 */
const useAllMenuItem = () => {
  const {
    completeSignageSolutions,
    industrialMarking,
    nameTagsNamePlates,
    signsGraphics,
    stampsSeals,
  } = useStaticQuery(
    graphql`
      query {
        completeSignageSolutions: allMenuItem (
          filter: {
            parentUid: {
              ne: ""
            }
            uid: {
              regex: "/^complete-signage-solutions/"
            }
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuItemFragment
            }
          }
        }
        industrialMarking: allMenuItem (
          filter: {
            parentUid: {
              ne: ""
            }
            uid: {
              regex: "/^industrial-marking/"
            }
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuItemFragment
            }
          }
        }
        nameTagsNamePlates: allMenuItem (
          filter: {
            parentUid: {
              ne: ""
            }
            uid: {
              regex: "/^name-tags-name-plates/"
            }
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuItemFragment
            }
          }
        }
        signsGraphics: allMenuItem (
          filter: {
            parentUid: {
              ne: ""
            }
            uid: {
              regex: "/^signs-graphics/"
            }
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuItemFragment
            }
          }
        }
        stampsSeals: allMenuItem (
          filter: {
            parentUid: {
              ne: ""
            }
            uid: {
              regex: "/^stamps-seals/"
            }
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuItemFragment
            }
          }
        }
      }
    `
  );

  return {
    completeSignageSolutions: completeSignageSolutions?.edges || [],
    industrialMarking: industrialMarking?.edges || [],
    nameTagsNamePlates: nameTagsNamePlates?.edges || [],
    signsGraphics: signsGraphics?.edges || [],
    stampsSeals: stampsSeals?.edges || [],
  };
};

export default useAllMenuItem;