import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Popover, Transition } from '@headlessui/react';
import {
  ContactIcon,
  QuoteIcon,
} from '../Misc';

import { Cluster } from '../EveryLayout';
import { MenuLinkButton } from './';
import { MoreLinkAlt } from '../Misc';
import { Pattern } from '../Surface';

const callsToAction = [
  {
    name: 'Request a Quote',
    href: '/request-a-quote',
    icon: QuoteIcon,
  },
  {
    name: 'Contact Sales',
    href: '/contact',
    icon: ContactIcon,
  },
];

const MegaMenuPanel = ({ children, footer }) => (
  <Transition
    as={Fragment}
    enter="transition ease-out duration-200"
    enterFrom="opacity-0 -translate-y-1"
    enterTo="opacity-100 translate-y-0"
    leave="transition ease-in duration-150"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 -translate-y-1"
  >
    <Popover.Panel className="absolute z-30 top-full md:inset-x-0 transform shadow-lg bg-light-gray-300">
      <div className="relative z-10 flex flex-wrap">
        {children}
      </div>
      {footer && <MegaMenuFooter />}
    </Popover.Panel>
  </Transition>
);

const MegaMenuTitle = ({ title, summary, path }) => (
  <div className="basis-[16rem] 2xl:basis-[20rem] grow bg-primary-500">
    <div className="p-4 pt-8 h-full">
      <div className="h-full flex flex-col gap-16 justify-between">
        <div>
          <h3 className="font-normal text-lg leading-tight mb-2">
            <Link
              to={path}
              className="no-underline text-downy-400 hover:text-downy-300"
            >
              {title}
            </Link>
          </h3>
          <div className="prose prose-custom">
            <p className="text-sm text-white">
              {summary}
            </p>
          </div>
        </div>
        <MoreLinkAlt ariaLabel={`Learn more about ${title}`} to={path}>Learn more</MoreLinkAlt>
      </div>
    </div>
  </div>
);

const MegaMenuColumn = ({ children, className, pattern }) => (
  <div className={`relative ${className || ''}`}>
    {pattern && <Pattern pattern="diagonal lines" fill="#b5b4b0" className="absolute z-0 top-0 right-0 w-full h-full" />}
    <div className={`${pattern ? 'relative z-10': 'bg-light-gray-100 border-t border-light-gray-200'} p-4 pb-6 h-full`}>
      {children}
    </div>
  </div>
);

const MegaMenuFooter = () => (
  <div className="relative z-10 bg-dark-gray-500">
    <Cluster className="p-4 gap-6">
      {callsToAction.map((item) => (
        <div key={item.name}>
          <Link
            to={item.href}
            className="flex items-end no-underline text-light-gray-200 hover:text-light-gray-50 font-bold"
          >
            <span className="me-1 w-5 h-5">
              <item.icon />
            </span>
            <span className="">{item.name}</span>
          </Link>
        </div>
      ))}
    </Cluster>
  </div>
);

const MegaMenu = ({ title, summary, path, footer, children }) => (
  <Popover>
    {({ open }) => (
      <>
        <MenuLinkButton open={open}>
          {title}
        </MenuLinkButton>
        <MegaMenuPanel footer={footer}>
          {path
            && (
            <MegaMenuTitle
              title={title}
              summary={summary}
              path={path || '#'}
            />
          )}
          {children}
        </MegaMenuPanel>
      </>
    )}
  </Popover>
);

export {
  MegaMenu,
  MegaMenuColumn,
};