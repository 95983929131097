import React from 'react';
import { Link } from 'gatsby';

const MoreLink = ({ to, ariaLabel, className = '', children }) => {
  const aria = { 'aria-label': ariaLabel };
  return (
    <div className={className}>
      <Link to={to || '#'} className={`no-underline text-color-base ${className}`} {...aria}>
        {children}
      </Link> <span aria-hidden="true">&rarr;</span>
    </div>
  );
};

const MoreLinkAlt = ({ to, ariaLabel, className = '', children }) => {
  const aria = { 'aria-label': ariaLabel };
  return (
    <div className={className}>
      <Link to={to || '#'} className={`no-underline text-primary-100 hover:text-white`} {...aria}>
        {children}
      </Link> <span aria-hidden="true" className="text-primary-100">&rarr;</span>
    </div>
  );
};

export {
  MoreLink,
  MoreLinkAlt,
};