import React from 'react';
import { Link } from 'gatsby';
import { Popover } from '@headlessui/react';
import {
  ArrowLeftIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const MenuOpenButton = (props) => (
  <Popover.Button
    className="rounded-md p-2 inline-flex items-center justify-center hover:bg-gray-100 focus-visible:outline-none"
  >
    <span className="sr-only">Open menu</span>
    <Bars3Icon className="h-8 w-8" aria-hidden="true" />
  </Popover.Button>
);

const MenuCloseButton = (props) => (
  <Popover.Button
    className="rounded-xl p-2 inline-flex items-center justify-center text-downy-400 hover:text-downy-300 focus-visible:outline-none"
  >
    <span className="sr-only">Close menu</span>
    <XMarkIcon className="h-8 w-8" aria-hidden="true" />
  </Popover.Button>
);

const MenuBackButton = (props) => (
  <Popover.Button className="-m-2 bg-white rounded-md p-2 inline-flex items-center justify-center hover:bg-gray-100">
    <span className="sr-only">Close menu and go back</span>
    <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
  </Popover.Button>
);

const MenuLinkButton = ({ children, open = false }) => (
  <Popover.Button
    className={classNames(
      open ? 'text-color-base' : 'text-color-base',
      'group inline-flex items-center text-sm tracking-wide uppercase xl:text-[.9rem] 2xl:text-[1rem] font-bold focus-visible:outline-none py-2'
    )}
  >
    <span>{children}</span>
  </Popover.Button>
);

const MenuLinkButtonNoPopover = ({ children, to }) => (
  <Link
    to={to || '#'}
    activeClassName="active"
    partiallyActive={true}
    className="block no-underline text-color-base text-center text-sm tracking-wide uppercase xl:text-[.9rem] 2xl:text-[1rem] font-bold focus-visible:outline-none py-2"
  >
    <span>{children}</span>
  </Link>
);

const MenuLinkButtonMobile = ({ children, to = '#' }) => (
  <Popover.Button
    as={Link}
    activeClassName="active"
    partiallyActive={true}
    to={to}
    className="text-downy-400 hover:text-downy-300 text-lg no-underline group inline-flex items-center focus-visible:outline-none"
  >
    <span>{children}</span>
  </Popover.Button>
);

const MenuButtonMobile = ({ children, to = '#' }) => (
  <Popover.Button
    as={Link}
    to={to}
    className="inline-flex items-center justify-center border
      border-transparent text-center text-[.9rem] tracking-wide uppercase px-4 py-2
      rounded-xl  no-underline transition duration-hover
      bg-coral-a11y-500 hover:bg-coral-a11y-600 text-white hover:text-white shadow-sm w-full"
  >
    <span>{children}</span>
  </Popover.Button>
);

const MenuLinkAltButtonMobile = ({ children, to = '#' }) => (
  <Popover.Button
    as={Link}
    activeClassName="active"
    partiallyActive={true}
    to={to}
    className="text-primary-100 hover:text-white text-base font-light no-underline group inline-flex items-center focus-visible:outline-none"
  >
    <span>{children}</span>
  </Popover.Button>
);

const MenuExtLinkButtonMobile = ({ children, to = '#' }) => (
  <a
    href={to}
    className="block no-underline text-sm text-primary-100 hover:text-white"
    target="_blank"
  >
    <span>{children}</span>
  </a>
);


export {
  MenuBackButton,
  MenuButtonMobile,
  MenuCloseButton,
  MenuLinkButton,
  MenuLinkButtonNoPopover,
  MenuLinkButtonMobile,
  MenuLinkAltButtonMobile,
  MenuExtLinkButtonMobile,
  MenuOpenButton,
};
