import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../Elements';

const Pagination = ({ pageContext, children }) => {

  const { currentPage, numPages } = pageContext || {};
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? "/blog/" : `/blog/${(currentPage - 1).toString()}`;
  const nextPage = `/blog/${(currentPage + 1).toString()}`;

  return (
    <>
      {children}
      {( isFirst || isLast )
        && (
        <div className="py-8">
          <div className={`flex justify-between ${ numPages > 1 ? 'border-t' : '' } pt-8`}>
            <div>
              {!isFirst
                && (
                <Button to={prevPage} className="max-w-[10rem]">
                  &larr; Previous
                </Button>
              )}
            </div>
            <div>
              {!isLast
                && (
                <Button to={nextPage} colour="pine green" className="max-w-[10rem]">
                  Next &rarr;
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Pagination.propTypes = {
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
  }).isRequired,
};

export default Pagination;
