import React from 'react';
import PropTypes from 'prop-types';

import { Marquee } from '../Elements';
import { Pattern } from '../Surface';

const SectionWrapper = ({ children, className = '' }) => (
  <section className={`px-4 md:px-8 ${className}`}>
    {children}
  </section>
);

const Section = ({ children, theme = '', className = '' }) => {
  switch (theme) {
    case 'light pattern':
      return (
        <SectionWrapper className={`relative bg-downy-500 py-8 pb-16 md:pb-16 lg:py-0 lg:pb-0 ${className}`}>
          <div className="max-w-7xl mx-auto">
            <Pattern pattern="diagonal lines" fill="#6989aa" className="absolute z-0 bottom-0 left-0 w-[100%] h-[40%] bg-downy-500" />
            <div className="relative z-10">
              {children}
            </div>
            <div className="absolute bg-[rgba(197,239,237,.95)] left-0 right-0 bottom-0 z-10">
              <Marquee />
            </div>
          </div>
        </SectionWrapper>
      );
    case 'dark gray':
      return (
        <SectionWrapper className={`relative group dark-bg py-16 pb-20 bg-dark-gray-500 ${className}`}>
          <div className="max-w-7xl mx-auto">
            <Pattern pattern="diagonal lines" fill="#92979c" className="absolute z-0 bottom-0 top-0 left-0 right-[90%]" />
            <Pattern pattern="diagonal lines" fill="#92979c" className="absolute z-0 bottom-0 top-0 left-[90%] right-0" />
            {children}
          </div>
        </SectionWrapper>
      );
    case 'geo':
      return (
        <SectionWrapper className={`relative py-16 pb-20 bg-light-gray-100 ${className}`}>
          <div className="max-w-7xl mx-auto">
            <div className="hidden sm:block bg-pine-green-500 absolute z-0 bottom-0 top-0 left-0 right-[90%]" />
            <Pattern pattern="diagonal lines" fill="#92979c" className="absolute z-0 bottom-0 top-[30%] left-[50%] right-0" />
            <div className="relative z-10">
              {children}
            </div>
          </div>
        </SectionWrapper>
      );
    default:
      return (
        <SectionWrapper className={`relative ${className}`}>
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </SectionWrapper>
      );
  }
};

const SectionAlt = ({ children, className = '' }) => (
  <SectionWrapper className={`relative bg-gray-50 py-16 ${className}`}>
    <div className="max-w-7xl mx-auto">
      {children}
    </div>
  </SectionWrapper>
);

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(['dark gray','geo','light pattern']),
  className: PropTypes.string,
};

SectionAlt.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export {
  Section,
  SectionAlt,
  SectionWrapper,
};
