import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

const PureRecentPosts = ({ data }) => {
  return (
    <div className="mt-8 max-w-sm"> 
      <h3 className="text-lg font-bold">Latest Blog Posts</h3>
      {data.map(({ node }) => (
        <Link
          to={node.url}
          key={`recent-article-${node.id}`}
          className="block font-serif mt-4"
        >
          {node.data.title.text}
        </Link>
      ))}
    </div>
  );
};

const RecentPosts = props => {
  const { allPrismicArticle } = useStaticQuery(graphql`
    query {
      allPrismicArticle (
        sort: {
          data: {
            date: DESC
          }
        }
        limit: 5
      ) {
        edges {
          node {
            id
            uid
            data {
              title {
                text
              }
            }
            url
          }
        }
      }
    }
  `);

  const data = allPrismicArticle.edges;

  return <PureRecentPosts {...props} data={data} />;
};

export default RecentPosts;
