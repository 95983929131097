import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';

import {
  CalculatorIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  PuzzlePieceIcon,
  SparklesIcon,
  SwatchIcon,
  WrenchIcon,
} from '@heroicons/react/24/solid';

import {
  FadeIn,
  FadeInStagger,
} from '../Elements';
import {
  ServiceIcon,
} from '../Misc';

const ServiceCategoriesCategory = ({ node, icon }) => {

  const pageData = node.prismicPage[0] || null;

  return (
    <div>
      <dt>
        {icon
          && (
          <Link to={node.path} aria-hidden={true}>
            <div className="flex justify-center sm:justify-start">
              <ServiceIcon icon={icon} />
            </div>
          </Link>
        )}
        <h3 className="mt-5 text-lg text-center sm:text-left">
          <Link to={node.path}>
            {node.label}
          </Link>
        </h3>
      </dt>
      <dd className="mt-2 text-center sm:text-left max-w-xs sm:max-w-none mx-auto">{pageData.data?.summary}</dd>
    </div>
  );
};

const ServiceCategories = () => {
  const { allMenuItem } = useStaticQuery(graphql`
    query {
      allMenuItem (
        filter: {
          uid: {
            regex: "/^complete-signage-solutions-services/"
          }
        }
        sort: {
          weight: ASC
        }
      ) {
        edges {
          node {
            id
            label
            parentUid
            path
            prismicPage {
              data {
                summary
              }
            }
            uid
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div className="lg:col-span-1">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-center sm:text-left">Our Services</h2>
        </div>
        <FadeInStagger faster className="mt-10 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 sm:space-y-0 lg:col-span-2 lg:mt-0 xl:grid-cols-3">
          {allMenuItem.edges.map(({ node }, i) => {

            let icon = null;
            switch (node.uid) {
              case 'complete-signage-solutions-services-consulting':
                icon = ChatBubbleLeftRightIcon;
                break;
              case 'complete-signage-solutions-services-design-planning':
                icon = SwatchIcon;
                break;
              case 'complete-signage-solutions-services-custom-fabrication':
                icon = PuzzlePieceIcon;
                break;
              case 'complete-signage-solutions-services-sign-installation':
                icon = WrenchIcon;
                break;
              case 'complete-signage-solutions-services-project-management':
                icon = ClipboardDocumentCheckIcon;
                break;
              case 'complete-signage-solutions-services-sign-upkeep':
                icon = SparklesIcon;
                break;
              case 'complete-signage-solutions-services-site-surveys':
                icon = CalculatorIcon;
                break;
              default:
                icon = null;
            }

            return (
              <FadeIn key={`category-${i}`}>
                <ServiceCategoriesCategory key={node.id} node={node} icon={icon} />
              </FadeIn>
            )
          })}
        </FadeInStagger>
      </div>
    </>
  );
};

ServiceCategoriesCategory.propTypes = {
  node: PropTypes.object.isRequired,
  icon: PropTypes.object,
};

ServiceCategories.propTypes = {};

export default ServiceCategories;
