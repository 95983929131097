import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  CardCondensed,
  CardSubheader,
} from '../Elements';

import * as styles from '../EveryLayout/el.module.css';

const CaseStudy = ({ node }) => (
  <div className={`_center ${styles._center} h-full max-w-[600px]`}>
    <div className="group dark-bg">
      {node.data.images.length > 0
        && node.data.images[0].image?.gatsbyImageData
        && (
        <div className="relative z-0 !aspect-w-4 !aspect-h-3">
          <GatsbyImage
            image={node.data.images[0].image.gatsbyImageData}
            alt={node.data.images[0].alt || ''}
          />
        </div>
      )}
      <div className="-mt-8 w-[90%] mx-auto relative z-10">
        <CardCondensed>
          {node.data.title?.text
            && (
            <CardSubheader>
              <Link
                to={`/complete-signage-solutions/projects/${node.uid}/`}
                className="no-underline group-[.dark-bg]:text-downy-400"
              >
                {node.data.title.text}
              </Link>
            </CardSubheader>
          )}
        </CardCondensed>
      </div>
    </div>
  </div>
);

CaseStudy.propTypes = {
  node: PropTypes.object.isRequired,
};

export default CaseStudy;