import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Image } from '../Elements';
import { MegaMenu, MegaMenuColumn } from './MegaMenu';

const MenuSigns = () => {
  const { prismicPage, allPrismicPage } = useStaticQuery(graphql`
    query {
      prismicPage(
        uid: {
          eq: "signs-graphics"
        }
      ) {
        ...PrismicPageFragment
      }
      allPrismicPage(
        filter: {
          pagePath: {
            regex: "/^/signs-graphics//"
          }
        }
        sort: {
          order: ASC
        }
      ) {
        edges {
          node {
            id
            data {
              image {
                thumbnails {
                  StandardLandscape {
                    gatsbyImageData(width: 400)
                  }
                }
              }
              parent {
                document {
                  ... on PrismicPage {
                    uid
                  }
                }
              }
              title {
                text
              }
            }
            pagePath
            uid
          }
        }
      }
    }
  `);

  return (
    <MegaMenu
      title={prismicPage.data.title.text}
      summary={prismicPage.data?.summary || ''}
      path={prismicPage.pagePath}
      footer={false}
    >
      <MegaMenuColumn
        className="basis-0 grow-[999]"
        pattern={true}
      >
        <div className="flex gap-2">
          {/* Menu items with children */}
          <div className="grid grid-cols-3 gap-2">
            {allPrismicPage.edges.map(({ node }, i) => {
              const children = allPrismicPage.edges.filter(({ node: child }) => child.data.parent?.document?.uid === node.uid);
              if (node.data.parent?.document?.uid === 'signs-graphics' && children.length > 0) {
                return (
                  <div key={node.id}>
                    {node.data?.image?.thumbnails?.StandardLandscape?.gatsbyImageData
                      ? (
                      <Link to={node.pagePath} className="block">
                        <GatsbyImage
                          image={node.data.image.thumbnails.StandardLandscape.gatsbyImageData}
                          alt={node.data.image.thumbnails.StandardLandscape.alt || ''}
                          imgClassName="object-cover"
                        />
                      </Link>
                    ) : (
                      <Image src="/images/placeholder-4-3.png" alt="" />
                    )}
                    <Link to={node.pagePath} className="inline-block text-sm no-underline font-bold mt-2">{node.data.title.text}</Link>
                    {children.length > 0
                      && (
                      <ul className="mt-2">
                        {children.map(({ node: child }, i) => {
                          return (
                            <li key={child.id}>
                              <Link to={child.pagePath} className="inline-block text-sm no-underline font-light mt-1">{child.data.title.text}</Link>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                )
              }
              return null;
            })}
          </div>
          {/* Menu items without children */}
          <div className="grid grid-cols-2 gap-2">
            {allPrismicPage.edges.map(({ node }, i) => {
              const children = allPrismicPage.edges.filter(({ node: child }) => child.data.parent?.document?.uid === node.uid);
              if (node.data.parent?.document?.uid === 'signs-graphics' && children.length === 0) {
                return (
                  <div key={node.id}>
                    {node.data?.image?.thumbnails?.StandardLandscape?.gatsbyImageData
                      ? (
                      <Link to={node.pagePath} className="block">
                        <GatsbyImage
                          image={node.data.image.thumbnails.StandardLandscape.gatsbyImageData}
                          alt={node.data.image.thumbnails.StandardLandscape.alt || ''}
                        />
                      </Link>
                    ) : (
                      <Image src="/images/placeholder-4-3.png" alt="" />
                    )}
                    <Link to={node.pagePath} className="inline-block text-sm no-underline font-bold mt-2">{node.data.title.text}</Link>
                  </div>
                )
              }
              return null;
            })}
          </div>
        </div>
      </MegaMenuColumn>
    </MegaMenu>
  );
};

export default MenuSigns;