import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Image } from '../Elements';

import PlaceholderImage from '../../../static/images/placeholder-4-3.png';

const ProductCategories = ({ childPages }) => (
  <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
    {childPages.map(({ node }) => (
      <div key={node.id}>
        <div className="h-full">
          <Link
            to={node.pagePath}
            className="text-center hover:bg-gray-50 no-underline"
          >
            {node.data.image?.thumbnails?.StandardLandscape?.gatsbyImageData
              ? (
              <div>
                <div className="!aspect-w-4 !aspect-h-3">
                  <GatsbyImage
                    image={node.data.image.thumbnails.StandardLandscape.gatsbyImageData}
                    alt={node.data.image.thumbnails.StandardLandscape.alt || ''}
                  />
                </div>
              </div>
            ) : (
              <Image src={PlaceholderImage} alt="Placeholder image" />
            )}
            <div className="p-3 py-4 min-h-[5rem] font-bold">
              {node.data.title.text}
            </div>
          </Link>
        </div>
      </div>
    ))}
  </div>
);

ProductCategories.propTypes = {
  childPages: PropTypes.array.isRequired,
};

export default ProductCategories;
