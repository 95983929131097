import React from 'react';

import { Cluster } from '../EveryLayout';

const SocialShareEmail = (props) => (
  <svg className="text-color-base h-11 w-11 shadow-lg p-2 text-primary-100 bg-primary-500 rounded-xl" fill="currentColor" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path fill="none" fillRule="nonzero" d="M336 384l-288 0c-26.496,0 -48,-21.504 -48,-48l0 -288c0,-26.496 21.504,-48 48,-48l288 0c26.496,0 48,21.504 48,48l0 288c0,26.496 -21.504,48 -48,48z"/>
      <path fill="currentColor" d="M74.856 109.051l116.862 95.962 117.378 -95.962 -234.24 0zm-2.356 17.415l0 130.026 80.202 -65.013 -80.202 -65.013zm239 -0.675l-80.939 65.685 80.939 65.688 0 -131.373zm-147.457 74.881l-90.455 74.276 236.78 0 -90.995 -74.393 -27.727 22.493 -27.603 -22.376z"/>
    </g>
  </svg>
);

const SocialShareFacebook = (props) => (
  <svg className="text-color-base h-11 w-11 shadow-lg p-3 text-primary-100 bg-primary-500 rounded-xl" viewBox="0 0 512 512" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" height="100%" version="1.1" width="100%" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-106.468,0l0,-192.915l66.6,0l12.672,-82.621l-79.272,0l0,-53.617c0,-22.603 11.073,-44.636 46.58,-44.636l36.042,0l0,-70.34c0,0 -32.71,-5.582 -63.982,-5.582c-65.288,0 -107.96,39.569 -107.96,111.204l0,62.971l-72.573,0l0,82.621l72.573,0l0,192.915l-191.104,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Z"/>
  </svg>
);

const SocialShareLinkedIn = (props) => (
  <svg className="text-color-base h-11 w-11 shadow-lg p-2 text-primary-100 bg-primary-500 rounded-xl" fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path fill="none" d="M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z"/>
      <g>
        <rect fill="currentColor" height="14" width="4" x="7" y="11"/>
        <path fill="currentColor" d="M20.499,11c-2.791,0-3.271,1.018-3.499,2v-2h-4v14h4v-8c0-1.297,0.703-2,2-2c1.266,0,2,0.688,2,2v8h4v-7    C25,14,24.479,11,20.499,11z"/>
        <circle cx="9" cy="8" fill="currentColor" r="2"/>
      </g>
    </g>
  </svg>
);

const SocialShareTwitter = (props) => (
  <svg className="text-color-base h-11 w-11 shadow-lg p-2 text-primary-100 bg-primary-500 rounded-xl" viewBox="0 0 512 512" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path fill="none" fillRule="nonzero" d="M448,512l-384,0c-35.328,0 -64,-28.672 -64,-64l0,-384c0,-35.328 28.672,-64 64,-64l384,0c35.328,0 64,28.672 64,64l0,384c0,35.328 -28.672,64 -64,64Z"/>
      <path fill="currentColor" fillRule="nonzero" d="M196.608,386.048c120.704,0 186.752,-100.096 186.752,-186.752c0,-2.816 0,-5.632 -0.128,-8.448c12.8,-9.216 23.936,-20.864 32.768,-34.048c-11.776,5.248 -24.448,8.704 -37.76,10.368c13.568,-8.064 23.936,-20.992 28.928,-36.352c-12.672,7.552 -26.752,12.928 -41.728,15.872c-12.032,-12.8 -29.056,-20.736 -47.872,-20.736c-36.224,0 -65.664,29.44 -65.664,65.664c0,5.12 0.64,10.112 1.664,14.976c-54.528,-2.688 -102.912,-28.928 -135.296,-68.608c-5.632,9.728 -8.832,20.992 -8.832,33.024c0,22.784 11.648,42.88 29.184,54.656c-10.752,-0.384 -20.864,-3.328 -29.696,-8.192l0,0.896c0,31.744 22.656,58.368 52.608,64.384c-5.504,1.536 -11.264,2.304 -17.28,2.304c-4.224,0 -8.32,-0.384 -12.288,-1.152c8.32,26.112 32.64,45.056 61.312,45.568c-22.528,17.664 -50.816,28.16 -81.536,28.16c-5.248,0 -10.496,-0.256 -15.616,-0.896c28.928,18.432 63.488,29.312 100.48,29.312" />
    </g>
  </svg>
);

const SocialShareWrapper = ({ children }) => (
  <div>
    <Cluster className="gap-2">
      {children}
    </Cluster>
  </div>
);

export {
  SocialShareWrapper,
  SocialShareEmail,
  SocialShareFacebook,
  SocialShareLinkedIn,
  SocialShareTwitter,
};
