import React from 'react';
import { Link } from 'gatsby';
import { Popover } from '@headlessui/react';
import {
  EnvelopeIcon,
} from '@heroicons/react/24/solid';

import {
  Button,
} from '../Elements';
import {
  Cluster,
} from '../EveryLayout';
import {
  ContactIcon,
  ExternalLinkIcon,
} from '../Misc';
import { Logo } from '../Brand';
import {
  MenuIndustrial,
  MenuNameTags,
  MenuMore,
  MenuSigns,
  MenuSolutions,
  MenuStamps,
  MenuMobile,
  MenuOpenButton,
  MenuLinkButtonNoPopover,
} from '../Navigation';

import { useRequestAQuoteLink } from '../../hooks';

const Header = () => {
  const requestAQuoteLink = useRequestAQuoteLink();
  return (
    <div className="">
      <Popover className="relative">
        {({ open }) => (
          <>
            <div className="py-2 px-4 md:px-8">
              <div className="w-full flex justify-between">
                <div className="hidden lg:flex items-center">
                  <Cluster as="ul" className="mx-4">
                    <li>
                      <MenuLinkButtonNoPopover to={`/about/`}>
                        About
                      </MenuLinkButtonNoPopover>
                    </li>
                    <li>
                      <MenuLinkButtonNoPopover to={`/blog/`}>
                        Blog
                      </MenuLinkButtonNoPopover>
                    </li>
                    <li>
                      <MenuLinkButtonNoPopover to={`/contact/`}>
                        Contact
                      </MenuLinkButtonNoPopover>
                    </li>
                  </Cluster>
                </div>
                <div className="grow lg:grow-0 relative sm:flex sm:justify-end">
                  <div className="max-sm:flex max-sm:justify-center max-sm:me-auto max-sm:ms-auto">
                    <Cluster as="ul" className="justify-end">
                      <li>
                        <a href={`tel:7804264676`} className="py-2 flex items-end text-color-base no-underline font-bold">
                          <span className="me-1 w-6 h-6"><ContactIcon /></span>
                          <span>(780) 426-4676</span>
                        </a>
                      </li>
                      <li className="hidden xl:block">
                        <a href={`mailto:sales@royalstamp.ca`} className="py-2 flex items-end text-color-base no-underline font-bold">
                          <EnvelopeIcon className="me-[.5rem] h-6 w-6" aria-hidden="true" />
                          <span>sales@royalstamp.ca</span>
                        </a>
                      </li>
                      <li className="hidden sm:block">
                        <Button colour="coral" to={requestAQuoteLink}>
                          <span className="">Request a Quote</span>
                        </Button>
                      </li>
                      <li className="hidden md:block">
                        <Button colour="downy" to="https://rrs.utypia.com/UI/en-US" rel="noopener noreferrer" target="_blank">
                          Online Store<span className="whitespace-nowrap"><ExternalLinkIcon /></span>
                        </Button>
                      </li>
                    </Cluster>
                  </div>
                  <div className="md:hidden max-sm:absolute max-sm:top-0 max-sm:right-0">
                    <MenuOpenButton />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 z-30 pointer-events-none" aria-hidden="true" />
            <div className="relative z-20">
              <div className="flex justify-center lg:justify-start lg:items-end pt-4 pb-8 px-4 md:px-8">
                <div className="">
                  <Link to="/" className="flex me-auto ms-auto">
                    <Logo role="img" className="" alt="Return to homepage" />
                  </Link>
                </div>
                <div className="hidden md:block md:grow">
                  <div className="py-4 flex-1 flex md:items-center md:justify-end">
                    <Popover.Group as="nav" className="flex gap-4 2xl:gap-8">
                      <div>
                        <MenuSolutions />
                      </div>
                      <div className="hidden xl:block">
                        <MenuSigns />
                      </div>
                      <div className="xl:hidden">
                        <MenuMore />
                      </div>
                      <div className="hidden xl:block">
                        <MenuNameTags />
                      </div>
                      <div className="hidden xl:block">
                        <MenuStamps />
                      </div>
                      <div className="hidden xl:block">
                        <MenuIndustrial />
                      </div>
                    </Popover.Group>
                  </div>
                </div>
              </div>
            </div>

            <MenuMobile open={open} />
          </>
        )}
      </Popover>
    </div>
  )
};

export default Header;
