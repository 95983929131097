import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './el.module.css';

/**
 * Every Layout - The Center
 * Horizontally centers the element, not its children.
 */
const Center = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? `_center ${styles._center} ${props.className}` : `_center ${styles._center}`,
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Center (Intrinsic)
 * Horizontally centers the element AND its children. Centers children
 * regardless of their width.
 */
const CenterIntrinsic = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? `_centeri ${styles._centeri} ${props.className}` : `_centeri ${styles._centeri}`,
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Cluster
 */
const Cluster = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? `_cluster ${styles._cluster} ${props.className}` : `_cluster ${styles._cluster}`,
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Grid
 */
const Grid = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? `_grid ${styles._grid} ${props.className}` : `_grid ${styles._grid}`,
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Sidebar
 */
const SidebarLeft = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? `_sidebar _sidebarLeft ${styles._sidebar} ${styles._sidebarLeft} ${props.className}` : `_sidebar _sidebarLeft ${styles._sidebar} ${styles._sidebarLeft}`,
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

const SidebarRight = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? `_sidebar _sidebarRight ${styles._sidebar} ${styles._sidebarRight} ${props.className}` : `_sidebar _sidebarRight ${styles._sidebar} ${styles._sidebarRight}`,
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Stack
 */
const Stack = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? `_stack ${styles._stack} ${props.className}` : `_stack ${styles._stack}`,
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

/**
 * Every Layout - The Switcher
 */
const Switcher = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? `_switcher ${styles._switcher} ${props.className}` : `_switcher ${styles._switcher}`,
    style: props.style ? props.style : null,
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

Center.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  as: PropTypes.string,
};

CenterIntrinsic.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  as: PropTypes.string,
};

Cluster.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  as: PropTypes.string,
};

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  as: PropTypes.string,
};

SidebarLeft.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  as: PropTypes.string,
};

SidebarRight.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  as: PropTypes.string,
};

Stack.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  as: PropTypes.string,
};

Switcher.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  as: PropTypes.string,
};

export {
  Center,
  CenterIntrinsic,
  Cluster,
  Grid,
  SidebarLeft,
  SidebarRight,
  Stack,
  Switcher,
};
