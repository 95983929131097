import React from 'react';

const BeaverIcon = (props) => (
  <svg viewBox="0 0 64 64" {...props}>
    <g>
      <rect fill="none" />
      <path fill="currentColor" d="M24.243 42.347c-3.964,1.208 -9.354,1.632 -13.61,2.425 -2.223,0.414 -4.506,1.015 -6.667,1.397 -2.741,0.486 -4.416,1.826 -1.235,3.572 3.288,1.805 10.29,2.187 13.962,1.414 4.736,-0.998 8.306,-6.812 9.149,-7.012l2.325 2.376 -1.94 3.898 0.336 0.167c2.713,1.618 13.608,-0.708 18.649,-0.411 1.285,0.076 5.03,1.08 5.775,0.415 1.063,-1.986 -2.456,-3.936 -4.485,-3.845 -2.736,0.122 -4.443,0.999 -7.76,0.893 0.002,-0.004 3.97,-3.228 4.651,-4.007 1.612,-1.845 1.943,-3.015 4.4,-4.224 2.316,-1.141 4.067,-2.073 6.109,-0.394 1.977,1.627 4.003,-0.685 1.066,-3.665 0.83,-0.029 2.358,0.063 3.013,-0.505 0.712,-0.617 0.201,-1.904 -0.434,-2.426 -1.402,-1.151 -3.329,0.501 -5.758,0.016 -0.28,-2.214 1.539,-4.479 3.271,-5.697 0.697,-0.49 2.193,-1.19 3.009,-1.46 1.678,-0.556 1.188,-1.345 2.725,-1.821l-0.282 1.24c1.149,-0.041 1.698,-2.415 2.092,-3.405 0.779,-1.961 0.418,-2.391 -1.176,-3.518 -1.924,-1.363 -4.904,-3.389 -7.221,-3.774 -0.785,-0.131 -1.662,-0.142 -2.416,-0.304 -1.02,-0.22 -0.935,-0.913 -1.684,-1.114 -2.679,-0.719 -1.489,2.239 -2.241,2.81 -2.232,1.695 -4.86,3.176 -7.703,3.396 -5.952,0.461 -11.854,1.79 -15.295,6.714 -1.723,2.465 -2.934,4.904 -2.943,8.072 -0.005,1.613 0.098,3.441 0.524,4.992 0.553,2.016 1.158,2.232 1.794,3.785z" />
    </g>
  </svg>
);

export default BeaverIcon;
