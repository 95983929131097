import React from 'react';
import { useLocation } from '@reach/router';

const useRequestAQuoteLink = () => {
  const location = useLocation();

  // if this is already a link to the request a quote page, return the current location
  if (location.pathname.match(/^\/request-a-quote/)) {
    return location.pathname;
  }

  // are we currently on a product page?
  const productPage = location.pathname.match(
    /^(\/complete-signage-solutions|\/signs-graphics|\/name-tags-name-plates|\/stamps-seals|\/industrial-marking)/
  );

  // if we are on a product page, return the request a quote link for that product
  if (productPage) {
    const link = `${location.origin}/request-a-quote/#${location.pathname.slice(1)}`;
    return link;
  }
  // otherwise, return the default request a quote link
  return '/request-a-quote/';
};

export default useRequestAQuoteLink;
