import React from 'react';
import PropTypes from 'prop-types';

import { Cluster } from '../EveryLayout';
import { Pattern } from '../Surface';
import { MenuLinkButtonNoPopover } from './';

const Submenu = ({ childPages }) => (
  <section className="relative !mt-0">
    <div className="relative max-w-7xl+ mx-auto bg-light-gray-200">
      <Cluster className="relative z-10 justify-center py-4">
        {childPages.map(({ node }) => (
          <div key={`submenu-${node.id}`} className="pl-4 first:pl-0">
            <MenuLinkButtonNoPopover to={node.pagePath}>
              {node.data.title.text}
            </MenuLinkButtonNoPopover>
          </div>
        ))}
      </Cluster>
      <Pattern pattern="diagonal lines" fill="#c9c8c3" className="absolute z-0 top-0 right-0 w-full h-full" />
    </div>
  </section>
);

Submenu.propTypes = {
  childPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Submenu;
