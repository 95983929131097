import React from 'react';
import PropTypes from 'prop-types';

const HiddenFormName = ({ formName }) => {

  return (
    <input type="hidden" name="Form" value={formName}/>
  );
}

HiddenFormName.proptTypes = {
  formName: PropTypes.string.isRequired,
};

export default HiddenFormName;
