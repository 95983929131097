import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Card } from '../Elements';
import { Switcher } from '../EveryLayout';
import { Pattern } from '../Surface';

const PageHeaderWrapper = ({ children, image, imgSrc }) => {
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <section className="relative bg-primary-500">
      <Pattern pattern="diagonal lines" fill="#92979c" className="absolute z-10 inset-0" />
      <div className="relative z-20 max-w-7xl+ mx-auto bg-primary-500">
        <Switcher className="relative z-0 h-full">
          <div className="flex items-center px-4 md:px-8 md:py-8">
            <div className="relative">
              <div className={`relative z-0 ${imgSrc ? 'pt-8 pb-4' : 'pt-12 pb-6'}`}>
                <Card className="!bg-primary-700 sm:min-w-[400px] lg:min-w-[600px]">
                  {children}
                </Card>
              </div>
            </div>
          </div>
          {image?.gatsbyImageData
            && (
            <div>
              <div className={`overflow-hidden h-full transition-opacity delay-[500ms] duration-[500ms] ${ loaded ? 'opacity-100' : 'opacity-0' }`}>
                <div className={`h-full relative
                  after:content-[''] after:absolute after:h-full after:w-full
                  after:top-0 after:left-0 after:mix-blend-lighten
                  after:bg-primary-500
                  before:content-[''] before:absolute before:h-full before:w-full
                  before:top-0 before:left-0 before:mix-blend-darken
                  before:bg-downy-500 before:z-10
                `}>
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.alt || ''}
                    className="w-full h-full"
                    imgClassName="object-cover w-full h-full"
                  />
                </div>
              </div>
            </div>
          )}
          {imgSrc
            && (
            <div className="">
              <div className={`overflow-hidden h-full transition-opacity delay-[500ms] duration-[500ms] ${ loaded ? 'opacity-100' : 'opacity-0' }`}>
                <div className={`h-full relative
                  after:content-[''] after:absolute after:h-full after:w-full
                  after:top-0 after:left-0 after:mix-blend-lighten
                  after:bg-primary-500
                  before:content-[''] before:absolute before:h-full before:w-full
                  before:top-0 before:left-0 before:mix-blend-darken
                  before:bg-downy-500 before:z-10
                `}>
                  <div className={`w-full h-full`}>
                    <img src={imgSrc} width="464" height="261" alt="" className="object-cover w-full h-full" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Switcher>
      </div>
    </section>
  );
};

const Breadcrumb = ({ crumbs, prismicPage }) => {
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  let breadcrumb = [];

  // Recursive function to build a breadcrumb based on parent-child
  // relations.
  const buildPath = (inputNode, i = 0) => {
    // Find the parent node.
    const parentNode = inputNode.data?.parent?.document || null;
    // If there is no parent node, time to return.
    if (!parentNode) {
      return;
    }
    // Build the breadcrumb.
    breadcrumb.unshift({ path: parentNode.pagePath || '#', label: parentNode.data.title.text });
    // Recurse.
    return buildPath(parentNode, i + 1);
  };

  if (prismicPage) {
    buildPath(prismicPage);
    crumbs = breadcrumb;
  }

  if (crumbs.length > 0) {
    return (
      <div className={`mb-4 transition-opacity delay-200 duration-500 ease-out ${ loaded ? 'opacity-100' : 'opacity-0' }`}>
        {crumbs.map((crumb, i) => (
          <React.Fragment key={`breadcrumb-${i}`}>
            {i > 0
              && (
              <span aria-hidden="true" className="text-primary-100"> &rarr; </span>
            )}
            <Link to={crumb.path} className="uppercase text-sm no-underline tracking-wider text-primary-100 hover:text-white hover:underline">{crumb.label}</Link>
          </React.Fragment>
        ))}
      </div>
    );
  }
  return null;
};

const PageHeaderHeading = ({ children, className }) => {
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <h1 className={`mb-4 text-2xl sm:text-3xl xl:text-4xl font-serif font-merriweather-extrabold max-w-4xl text-downy-400 transition-all delay-100 duration-300 ease-out ${ loaded ? 'opacity-100 scale-y-100 translate-y-0' : 'opacity-0 scale-y-90 -translate-y-3' } ${className || ''}`}>
      {children}
    </h1>
  );
};

const PageHeaderDescription = ({ children, className }) => (
  <div className={`prose prose-custom ${className || ''}`}>
    <span className="text-base text-white">
      {children}
    </span>
  </div>
);

PageHeaderWrapper.propTypes = {
  image: PropTypes.object,
  children: PropTypes.node.isRequired,
};

Breadcrumb.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })),
  prismicPage: PropTypes.object,
};

PageHeaderHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

PageHeaderDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  PageHeaderWrapper,
  Breadcrumb,
  PageHeaderHeading,
  PageHeaderDescription,
};
