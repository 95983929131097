/** @module Errors */
/**
 * @class FileTypeError
 * @classdesc Error thrown when a file is not of the expected type.
 * @param {string} message - Error message.
 * @example
 * throw new FileTypeError('File must be a PDF');
 */ 
class FileTypeError extends Error {
  constructor(message) {
    super(message);
    this.name = 'FileTypeError';
  }
}

/**
 * @class FileSizeError
 * @classdesc Error thrown when a file is larger than the expected size.
 * @param {string} message - Error message.
 * @example
 * throw new FileSizeError('File must be less than 25MB');
 */ 
class FileSizeError extends Error {
  constructor(message) {
    super(message);
    this.name = 'FileSizeError';
  }
}

export {
  FileTypeError,
  FileSizeError,
};
