const applyDefaultDisplay = true;
const applyDefaultLayout = true;
const applyGlobalDisplay = true;
const applyGlobalLayout = true;

const checkboxGroupTheme = {
  display: {
    // input: '',
    // label: '',
    inputFocusRing: 'focus:ring-primary-500 focus:border-primary-500',
  },
  layout: {
    // column: '',
    // input: '',
    // radioGroup: '',
    // radioItem: '',
  }
};

const inputTheme = {
  display: {
    input: 'shadow-sm placeholder-gray-500 border-gray-300 rounded-md',
    inputFocusRing: 'focus:ring-primary-500 focus:border-primary-500',
  },
  layout: {
    input: 'block py-2 px-3',
    inputWidth: 'w-full',
  }
};

const inputThemeShort = {
  display: {
    input: 'shadow-sm placeholder-gray-500 border-gray-300 rounded-md',
    inputFocusRing: 'focus:ring-primary-500 focus:border-primary-500',
    inputWidth: 'w-20',
  },
  layout: {
    input: 'block py-2 px-3',
    // inputFocusRing: '',
    // inputWidth: '',
  }
};

const radioTheme = {
  display: {
    // input: '',
    radioItemFocusRing: 'focus:ring-primary-500 focus:border-primary-500',
  },
  layout: {
    // column: '',
    // input: '',
    // radioGroup: '',
    // radioItem: '',
  }
};

const selectTheme = {
  display: {
    // use :first-child select to style the selected color
    select: 'shadow-sm placeholder-gray-500 border-gray-300 rounded-md',
    selectFocusRing: 'focus:ring-primary-500 focus:border-primary-500',
  },
  layout: {
    // selectMarginBottom: '',
    // selectWidth: '',
  }
};

const textareaTheme = {
  display: {
    textarea: 'shadow-sm placeholder-gray-500 border-gray-300 rounded-md',
    textareaFocusRing: 'focus:ring-primary-500 focus:border-primary-500',
  },
  layout: {
    textarea: 'block py-3 px-4',
    textareaWidth: 'w-full',
  }
};

export {
  applyDefaultDisplay,
  applyDefaultLayout,
  applyGlobalDisplay,
  applyGlobalLayout,
  checkboxGroupTheme,
  inputTheme,
  inputThemeShort,
  radioTheme,
  selectTheme,
  textareaTheme,
 };
