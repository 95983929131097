import React from 'react';
import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useAllMenuItemTopServicesProducts = () => {
  const menuItems = useStaticQuery(
    graphql`
      query {
        allMenuItem (
          filter: {
            uid: {
              regex: "/[^blog|^about|^contact]/"
            }
            parentUid: {
              eq: ""
            }
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuItemFragment
            }
          }
        }
      }
    `
  );

  return menuItems.allMenuItem?.edges || [];
};

export default useAllMenuItemTopServicesProducts;