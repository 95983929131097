import React from 'react';
import PropTypes from 'prop-types';

const defaultClasses = `inline-flex items-center justify-center border 
  border-transparent text-center text-[.9rem] tracking-wide uppercase px-4 py-2 
  rounded-xl  no-underline transition duration-hover`;

const FormButton = ({ children, className = '', type = 'submit', ...rest }) => {
  switch (type) {
    // same as "pine green" Link
    case 'submit':
    default:
      return (
        <button type="submit"
          className={`${defaultClasses} bg-pine-green-500 hover:bg-pine-green-600 text-white hover:text-white shadow-sm ${className}`}
          {...rest}
        >
          {children}
        </button>
      );
  }
};

FormButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([ 'submit', 'reset' ]),
};

export default FormButton;
