import React from 'react';

const SectionHeader = ({ children, className }) => (
  <div className={`relative group mb-4 sm:mb-8 text-center ${className || ''}`}>
    {children}
  </div>
);

const SectionHeaderAlt = ({ children, className }) => (
  <div className={`group alt mb-8 ${className || ''}`}>
    {children}
  </div>
);

const SectionHeaderHeading = ({ children, className }) => (
  <h2 className={`group-[.text-center]:mx-auto group-[.dark-bg]:text-white text-2xl md:text-3xl font-bold ${className || ''}`}>
    {children}
  </h2>
);

const SectionHeaderDescription = ({ children, className }) => (
  <p className={`group-[.text-center]:mx-auto max-w-2xl mt-4 font-light group-[.dark-bg]:text-white sm:text-lg ${className || ''}`}>
    {children}
  </p>
);

export {
  SectionHeader,
  SectionHeaderAlt,
  SectionHeaderHeading,
  SectionHeaderDescription,
};