import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Image } from '../Elements';
import {
  Cluster,
} from '../EveryLayout';
import {
  BeaverIcon,
  ExternalLinkIcon,
  StampIcon,
} from '../Misc';

import { MegaMenu, MegaMenuColumn } from './MegaMenu';

const LinkFeatureWrapper = ({ children }) => (
  <Cluster as="li" className="gap-3 py-1 px-4 bg-dark-gray-500 rounded-xl shadow-lg max-w-xs mx-auto">
    {children}
  </Cluster>
);

const MenuStamps = () => {
  const { prismicPage, allPrismicPage } = useStaticQuery(graphql`
    query {
      prismicPage(
        uid: {
          eq: "stamps-seals"
        }
      ) {
        ...PrismicPageFragment
      }
      allPrismicPage(
        filter: {
          data: {
            parent: {
              uid: {
                eq: "stamps-seals"
              }
            }
          }
        }
        sort: {
          order: ASC
        }
      ) {
        edges {
          node {
            id
            data {
              image {
                thumbnails {
                  StandardLandscape {
                    gatsbyImageData(width: 400)
                  }
                }
              }
              parent {
                document {
                  ... on PrismicPage {
                    uid
                  }
                }
              }
              title {
                text
              }
            }
            pagePath
            uid
          }
        }
      }
    }
  `);

  return (
    <MegaMenu
      title={prismicPage.data.title.text}
      summary={prismicPage.data?.summary || ''}
      path={prismicPage.pagePath}
      footer={false}
    >
      <MegaMenuColumn
        className="basis-[20rem] grow-[999]"
      >
        <div>
          <div className="grid grid-cols-4 gap-2">
            {allPrismicPage.edges.map(({ node }) => {
                //if (i > 2) return null;
                return (
                <div key={node.id}>
                  {node.data?.image?.thumbnails?.StandardLandscape?.gatsbyImageData
                    ? (
                    <Link to={node.pagePath} className="block">
                      <GatsbyImage
                        image={node.data.image.thumbnails.StandardLandscape.gatsbyImageData}
                        alt={node.data.image.thumbnails.StandardLandscape.alt || ''}
                        imgClassName="object-cover"
                      />
                    </Link>
                  ) : (
                    <Image src="/images/placeholder-4-3.png" alt="" />
                  )}
                  <div>
                    <Link to={node.pagePath} className={`inline-block text-sm no-underline font-bold mt-2`}>{node.data.title.text}</Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MegaMenuColumn>
      <MegaMenuColumn
        className="basis-[26rem] grow"
        pattern={true}
      >
        <div className="h-full flex items-center">
          <Cluster as="ul">
            <LinkFeatureWrapper>
              <div className="text-light-gray-200">
                <BeaverIcon className="h-14 w-14" aria-hidden="true" />
              </div>
              <div className="flex-1">
                <a href="https://www.permitstamps.ca/" target="_blank" rel="noopener noreferrer" className="block no-underline text-light-gray-200 hover:text-light-gray-100">
                  Buy APEGA Permit to Practice Stamps Online<span className="whitespace-nowrap"><ExternalLinkIcon /></span>
                </a>
              </div>
            </LinkFeatureWrapper>
            <LinkFeatureWrapper>
              <div className="text-light-gray-200">
                <StampIcon className="h-14 w-14" aria-hidden="true" />
              </div>
              <div className="flex-1">
                <a href="https://rrs.utypia.com/UI/en-US" target="_blank" rel="noopener noreferrer" className="block no-underline text-light-gray-200 hover:text-light-gray-100">
                  Buy Stamps & Corporate Seals Online<span className="whitespace-nowrap"><ExternalLinkIcon /></span>
                </a>
              </div>
            </LinkFeatureWrapper>
          </Cluster>
        </div>
      </MegaMenuColumn>
    </MegaMenu>
  );
};

export default MenuStamps;